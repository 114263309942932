import { Route, Routes } from "react-router-dom";
import React, { Suspense } from "react";
import { Spinner } from "../components/utils";

const ConfidentialityManagement = React.lazy(() =>
  import("../views/confidentiality/ConfidentialityManagement")
);

const ConfidentialityManagementRoutes = (props) => {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <Suspense fallback={<Spinner />}>
            <ConfidentialityManagement {...props} />
          </Suspense>
        }
      />
    </Routes>
  );
};

export default ConfidentialityManagementRoutes;
