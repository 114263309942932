import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import MessagePage from "../../components/Pages/MessagePage";
import { Spinner } from "../../components/utils";

const AccessDeniedPage = () => {
  const { state } = useLocation();
  const [isShow, setIsShow] = useState(false);
  useEffect(() => {
    document.title = `Access Denied | MX Official Documentation`;
  }, []);

  useEffect(() => {
    if (state.prevPath) {
      window.history.replaceState(null, null, state.prevPath);
      setIsShow(true);
    }
  }, [state.prevPath]);

  if (isShow) {
    return (
      <MessagePage
        title="Access Denied"
        description="This document is not publicly available. Please contact Murex."
        help={
          <span>
            Please visit the <Link to="/">homepage</Link> or try searching.
          </span>
        }
      />
    );
  } else {
    return <Spinner />;
  }
};

export default AccessDeniedPage;
