import Constants from "./Constants";

export const addUserAction = (user) => {
  return {
    type: Constants.ADD_USER,
    payload: user,
  };
};

export const deleteUserAction = () => {
  return {
    type: Constants.DELETE_USER,
    payload: {},
  };
};
