import Constants from "../actions/Constants";

export const versionReducer = (version = {}, action) => {
  if (action.type === Constants.CHANGE_VERSION) {
    return action.payload;
  }
  return version;
};

export const versionsReducer = (versions = [], action) => {
  if (action.type === Constants.POPULATE_VERSIONS) {
    return action.payload;
  }

  return versions;
};
