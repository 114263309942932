import Constants from "../actions/Constants";

export const userReducer = (user = {}, action) => {
  if (
    action.type === Constants.ADD_USER ||
    action.type === Constants.DELETE_USER
  ) {
    return action.payload;
  }
  return user;
};
