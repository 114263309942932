import React, { useEffect, useState } from "react";
import FontAwesome from "react-fontawesome";
import { connect } from "react-redux";

import {
  openSideBarAction,
  closeSideBarAction,
} from "../../actions/sideBarActions";

const DraggableDrawerToggle = ({
  sidebarWidth,
  openSideBar,
  closeSideBar,
  isSideBarOpen,
}) => {
  const [iconName, setIconName] = useState("chevron-left");
  useEffect(() => {
    if (isSideBarOpen) {
      setIconName("chevron-left");
    } else {
      setIconName("chevron-right");
    }
  }, [isSideBarOpen]);

  let handleToggleEvent = () => {
    if (isSideBarOpen) {
      closeSideBar();
    } else {
      openSideBar();
    }
  };

  return (
    <span
      data-testid="sidebar-toggler"
      id="sidebar-toggler"
      onClick={handleToggleEvent}
      style={{
        left: `${sidebarWidth}px`,
      }}
    >
      <FontAwesome name={iconName} />
    </span>
  );
};

let mapStateToProps = (state) => {
  return {
    sidebarWidth: state.sidebarWidth,
    isSideBarOpen: state.isSideBarOpen,
  };
};

export default connect(mapStateToProps, {
  openSideBar: openSideBarAction,
  closeSideBar: closeSideBarAction,
})(DraggableDrawerToggle);
