import instance from "../instance";
import axios from "axios";

export const getConfig = (source = axios.CancelToken.source()) => {
  let cancelToken = source.token;

  return instance.get(`/v1/config/all`, {
    cancelToken,
  });
};
