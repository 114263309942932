import Constants from "../actions/Constants";

let searchDataInitState = {
  isComputing: true,
  query: "",
  version: null,
};
export const searchReducer = (searchData = searchDataInitState, action) => {
  if (action.type === Constants.CHANGE_SEARCH_DATA) {
    return { ...searchData, ...action.payload };
  }
  return searchData;
};
