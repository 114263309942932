import Constants from "../actions/Constants";
import { updateTreeAction } from "../actions/treeActions";
import { getSubTreeByPageId } from "../api/doc/treeApi";
import reduxStore from "../helpers/reduxStore";

export const getAllParentOfPage = (pageId, childParentMap, arr = []) => {
  if (childParentMap && !childParentMap[pageId]) {
    return arr;
  }
  let parentId = childParentMap[pageId].pageId;
  arr.push(parentId);

  return getAllParentOfPage(parentId, childParentMap, arr);
};

const mapChildParentHelper = (data, map, parentObject) => {
  if (data.pageId) {
    map[data.pageId] = parentObject;
    if (data.children) {
      data.children.forEach((element) => {
        let { pageId, title } = data;
        mapChildParentHelper(element, map, { pageId, title });
      });
    }
  }

  return map;
};

export const mapChildParent = (data) => {
  return mapChildParentHelper(data, {}, null); // if value is null, then it is the root
};

let mapParentChildrenHelper = (data, map) => {
  if (data.pageId) {
    map[data.pageId] = [];
    if (data.children) {
      data.children.forEach((element) => {
        let { pageId, title, position } = element;
        map[data.pageId].push({ pageId, title, position });

        mapParentChildrenHelper(element, map);
      });

      map[data.pageId] = map[data.pageId].sort(
        (x, y) => x.position - y.position
      );
    }
  }

  return map;
};
export const mapParentChildren = (data) => {
  return mapParentChildrenHelper(data, {});
};

export const updatePageChildrenInTreeData = async (
  data,
  parentsOfPage,
  targetPageId
) => {
  let isUpdated = false;

  if (parentsOfPage.length > 0) {
    let currentPageId = parentsOfPage[0];
    if (data.pageId === currentPageId) {
      parentsOfPage.shift();
      for (let page of data.children) {
        isUpdated =
          isUpdated ||
          (await updatePageChildrenInTreeData(
            page,
            parentsOfPage,
            targetPageId
          ));
      }
    }
  } else {
    if (data.pageId === targetPageId) {
      if (
        data.hasChildren === true &&
        (!data.children || data.children.length === 0)
      ) {
        let response = await getSubTreeByPageId(data.pageId);
        data.children = response.data.children;
        isUpdated = true;
      }
    }
  }

  return isUpdated;
};

export const setPageChildren = (pageId, tree) => {
  if (tree.childParentMap[pageId]) {
    let parentsOfPage = getAllParentOfPage(pageId, tree.childParentMap);

    parentsOfPage = parentsOfPage.reverse();

    let newData = JSON.parse(JSON.stringify(tree.data));

    updatePageChildrenInTreeData(newData, parentsOfPage, pageId).then(
      (isUpdated) => {
        if (isUpdated) {
          reduxStore.dispatch(
            updateTreeAction({
              version: tree.version,
              data: newData,
              source: Constants.SOURCE_SUB_TREE_UPDATE,
            })
          );
        }
      }
    );
  }
};
