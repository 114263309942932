import React, { useState, useEffect } from "react";
import FontAwesome from "react-fontawesome";

import "./GoUpButton.css";

const GoUpButton = () => {
  const [display, setDisplay] = useState("none");

  useEffect(() => {});

  useEffect(() => {
    let scrollSetDisplay = (e) => {
      window.scrollY > 200 ? setDisplay("inline-block") : setDisplay("none");
    };

    window.addEventListener("scroll", scrollSetDisplay);
    return () => window.removeEventListener("scroll", scrollSetDisplay);
  }, []);

  let handleGoUpEvent = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  return (
    <>
      <span id="go-up-button" data-testid="goUpButton" style={{ display }} onClick={handleGoUpEvent}>
        <FontAwesome name="chevron-up" />
      </span>
    </>
  );
};

export default GoUpButton;
