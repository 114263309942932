import {updateAccount} from "../api/doc/accountApi";

export const updateAccountId = async (accountId) => {
  try {
    await updateAccount({accountId: accountId})
    localStorage.setItem("accountId", accountId);
    localStorage.setItem("timeAccountSelected", new Date().getTime());
  } catch (error) {
    localStorage.removeItem("accountId");
    throw error;
  }
}

export const hasAccountIdExpired = () => {
  const timeToOpenAgain = parseInt(localStorage.getItem("timeAccountSelected")) + 86400000;
  const localStorageAccountId = localStorage.getItem("accountId");

  if (!localStorageAccountId || !timeToOpenAgain) {
    return true;
  }

  return new Date().getTime() > timeToOpenAgain;

}

export const isAccountIdInvalid = (config) => {
  const localStorageAccountId = localStorage.getItem("accountId");
  return localStorageAccountId !== config.USER_ACCOUNT_ID;
}