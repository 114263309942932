import instance from "../instance";
import axios from "axios";

export const getAttachment = (
  url,
  onDownloadProgress = () => {},
  source = axios.CancelToken.source()
) => {
  let cancelToken = source.token;

  return instance.get(url, {
    cancelToken,
    responseType: "blob",
    onDownloadProgress: onDownloadProgress,
  });
};
