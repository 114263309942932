import reduxStore from "../helpers/reduxStore";

export default class TcService {
  static isInitialized = false;
  static isInitializing = false;
  static instance = null;

  static init = () => {
    return new Promise((resolve, reject) => {
      if (TcService.isAttachmentViewerUrl()) {
        return reject();
      }

      if (
        reduxStore.getState().c &&
        !TcService.isInitializing &&
        !TcService.isInitialized
      ) {
        TcService.isInitializing = true;

        let script = document.createElement("script");

        script.src = process.env.REACT_APP_TC_PLUGIN_JS_URL;
        script.type = "text/javascript";

        document.head.appendChild(script);

        script.onload = () => {
          let instance = new window.TcScript({
            userJwt: window.userData.access_token,
            cache: true,
          });
          this.instance = instance;
          this.instance.initTcPopup();

          TcService.isInitialized = true;
          TcService.isInitializing = false;
          resolve();
        };

        script.onerror = (e) => {
          reject(e);
        };
      } else {
        reject();
      }
    });
  };

  static initTcPopup = () => {
    if (this.instance) {
      this.instance.initTcPopup();
    }
  };

  static initTcViewer = () => {
    if (this.instance) {
      this.instance.initTcViewer();
    }
  };

  static isAttachmentViewerUrl = () => {
    return window.location.pathname.startsWith("/download/attachment/");
  };
}
