import Constants from "./Constants";
import { getReverseTreeByPageId, getTreeByVersion } from "../api/doc/treeApi";

export const updateTreeAction = (tree) => {
  return {
    type: Constants.UPDATE_TREE,
    payload: tree,
  };
};

let updateTreeByVersion = async (dispatch, version) => {
  let data = {};
  try {
    let response = await getTreeByVersion(version);
    data = response.data;
  } catch (e) {}

  dispatch(
    updateTreeAction({
      data,
      version,
      source: Constants.SOURCE_VERSION_TREE_UPDATE,
    })
  );
};

export const updateReverseTreeByPageIdAction = ({
  pageId,
  version,
  source,
}) => {
  return async (dispatch, getState) => {
    let state = getState();

    if (source === Constants.SOURCE_DONT_UPDATE_TREE) {
      return;
    }
    if (
      source === Constants.SOURCE_NOT_AVAILABLE ||
      source === Constants.SOURCE_NOT_FOUND ||
      source === Constants.SOURCE_ACCESS_DENIED
    ) {
      await updateTreeByVersion(dispatch, version);
    } else if (state.tree.childParentMap[pageId] === undefined) {
      // if the page requested is not inside the tree, get the tree by reverse
      let data = {};
      try {
        let response = await getReverseTreeByPageId(pageId);
        data = response.data;
        dispatch(
          updateTreeAction({
            data,
            version,
            source: Constants.SOURCE_REVERSE_TREE_UPDATE,
          })
        );
      } catch (e) {
        await updateTreeByVersion(dispatch, version);
      }
    }
  };
};
