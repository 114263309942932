import instance from "../instance";
import axios from "axios";

export const getTreeByVersion = (
  version,
  source = axios.CancelToken.source()
) => {
  let cancelToken = source.token;

  return instance.get(`/v1/documents/tree/version/${version}`, {
    cancelToken,
  });
};

export const getSubTreeByPageId = (
  pageId,
  source = axios.CancelToken.source()
) => {
  let cancelToken = source.token;

  return instance.get(`/v1/documents/tree/page/${pageId}`, {
    cancelToken,
  });
};

export const getReverseTreeByPageId = (
  pageId,
  source = axios.CancelToken.source()
) => {
  let cancelToken = source.token;

  return instance.get(`/v1/documents/tree/page/pageId/reverse/${pageId}`, {
    cancelToken,
  });
};
