import AuthService from "./AuthService";
import reduxStore from "../helpers/reduxStore";
import { changeSearchDataAction } from "../actions/searchActions";
import MatomoService from "./MatomoService";

export default class MindbreezeService {
  static isScriptAdded = false;
  static isInitializing = false;
  static app = null;

  static init = () => {
    return new Promise((resolve, reject) => {
      if (MindbreezeService.isInitializing) {
        // to avoid multiple calls at the same time
        setTimeout(() => {
          MindbreezeService.init()
            .then((response) => {
              resolve(response);
            })
            .catch((err) => {
              reject(err);
            });
        }, 500);
      } else {
        MindbreezeService.isInitializing = true;

        MindbreezeService.load();
        // if app exists bas its root element is not connected to the HTML DOM, it means we exited the /search page and reentered, we need to reinitialize
        if (
          MindbreezeService.app &&
          MindbreezeService.isSearchPage() &&
          (!MindbreezeService.app.rootEls ||
            MindbreezeService.app.rootEls.length === 0 ||
            !MindbreezeService.app.rootEls[0].isConnected) // always its the search page
        ) {
          MindbreezeService.app = null;
        }

        // if mindbreeze is initialized, resolve
        if (window.Mindbreeze && MindbreezeService.app) {
          MindbreezeService.stopLoad();
          MindbreezeService.isInitializing = false;

          return resolve(true);
        }

        // if script tag is not added, add the script tag and initialize mindbreeze app
        if (MindbreezeService.isScriptAdded === false) {
          let mbUrl = reduxStore.getState().config.MINDBREEZE_URL;
          let scriptTag = document.createElement("script");
          scriptTag.type = "text/javascript";
          scriptTag.src = mbUrl;
          scriptTag.onload = () => {
            MindbreezeService.authenticateMindbreezeApp()
              .then((result) => {
                MindbreezeService.isInitializing = false;
                resolve(true);
              })
              .catch(() => {
                MindbreezeService.isInitializing = false;
                reject(false);
              });
          };

          scriptTag.onerror = (err) => {
            MindbreezeService.isInitializing = false;
            reject(err);
          };

          document.querySelector("head").appendChild(scriptTag);
          MindbreezeService.isScriptAdded = true;
        }
        // if script tag is added but mindbreeze is not initialized => initialize mindbreeze
        else if (
          MindbreezeService.isScriptAdded === true &&
          window.Mindbreeze &&
          !MindbreezeService.app
        ) {
          MindbreezeService.authenticateMindbreezeApp()
            .then((result) => {
              MindbreezeService.isInitializing = false;
              resolve(true);
            })
            .catch(() => {
              MindbreezeService.isInitializing = false;
              reject(false);
            });
        } else {
          MindbreezeService.isInitializing = false;
          resolve(true);
        }
      }
    });
  };
  static load = () => {
    reduxStore.dispatch(changeSearchDataAction({ isComputing: true }));
  };

  static stopLoad = () => {
    reduxStore.dispatch(changeSearchDataAction({ isComputing: false }));
  };

  static authenticateMindbreezeApp = () => {
    return new Promise((resolve, reject) => {
      if (!window.Mindbreeze) {
        return reject();
      }
      window.Mindbreeze.require(
        ["client/application"],
        function (Application, Channels) {
          let MindbreezeApp = Application.extend({
            searchModelInitialized: function () {
              Application.prototype.searchModelInitialized.call(this);

              let version = reduxStore.getState().searchData.version;

              this.models.search.set("constraint", {
                and: [
                  {
                    label: "ContentType",
                    quoted_term: "Official Documentation",
                  },
                  {
                    label: "DocVersion",
                    quoted_term: version,
                  },
                ],
              });

              this.models.search.on(
                "computing",
                function (model, atts, options) {
                  let query = reduxStore.getState().searchData.query;
                  document.title = `${query} | MX Official Documentation Search`;
                  MindbreezeService.load();
                }
              );
              this.models.search.on(
                "computed",
                function (model, atts, options) {
                  let query = reduxStore.getState().searchData.query;
                  document.title = `${query} | MX Official Documentation Search`;
                  MatomoService.pushTrackPageView();
                  MindbreezeService.stopLoad();

                  //show the version picker
                  let versionContainer = document.getElementById(
                    "MxDocVersion-Container"
                  );
                  if (
                    versionContainer &&
                    MindbreezeService.isSearchPage() &&
                    model !== undefined
                  ) {
                    versionContainer.style.display = "block";
                  }
                }
              );

              resolve(true);
            },
          });

          let rootEls = [];
          let mindbreezeContainer = document.getElementById(
            "mindbreeze-container"
          );
          if (mindbreezeContainer) {
            rootEls.push(mindbreezeContainer);
          } else {
            rootEls.push(document.createElement("div"));
          }

          rootEls.push(document.getElementById("search-form"));
          let mindbreezeConfig = AuthService.getMindbreezeTokenFromStorage();
          let applicationProperties = {
            enablePersonalization: true,
            startSearch: false,
            enableProfile: true,
            disableClientNotifications: true,
            updateTitle: true,
            rootEls,
            apiRequestHeaders: {
              Authorization: `Bearer ${
                mindbreezeConfig ? mindbreezeConfig.access_token : ""
              }`,
            },
            useLegacyMessageFrameChannel: true,
            useMessageFrameForNonBuiltinSources: true,
            revalidateFirstPage: false,
            personalizationQueryContextProperties: function () {
              const accountId = localStorage?.accountId;
              if (accountId) {
                return [{ key: "account_id", value: localStorage.accountId }];
              } else {
                let userAccounts = reduxStore.getState().config.USER_ACCOUNTS;
                if (userAccounts && Object.keys(userAccounts).length === 1) {
                  return [
                    { key: "account_id", value: Object.keys(userAccounts)[0] },
                  ];
                }
              }
            },
          };

          MindbreezeService.app = new MindbreezeApp(applicationProperties);
        }
      );
    });
  };

  static triggerUserSearch = (updateConstraint = false) => {
    let app = MindbreezeService.app;

    if (app) {
      let inputOfTheUser = reduxStore.getState().searchData.query;

      if (inputOfTheUser) {
        inputOfTheUser = inputOfTheUser.replace(
          /doc\s*-*id\s*:\s*/gi,
          "DocID:"
        );

        if (updateConstraint) {
          let version = reduxStore.getState().searchData.version;

          app.models.search.set("constraint", {
            and: [
              {
                label: "ContentType",
                quoted_term: "Official Documentation",
              },
              {
                label: "DocVersion",
                quoted_term: version,
              },
            ],
          });
        }

        app.setUnparsedUserQuery(inputOfTheUser);
      }
    }
  };

  static isSearchPage = () => {
    return window.location.pathname === "/pages/search";
  };
}
