import React from "react";
import { Navbar } from "react-bootstrap";
import "./NavBarUrl.css";
import { Link } from "react-router-dom";
import { Tooltip } from "@mui/material";
const NavBarUrl = ({
  urlText = "",
  mobileUrlText,
  url = "",
  dataTestId = "",
  clickable = true,
  title = "",
  onClick = () => {},
}) => {
  let setLinkStyle = () => {
    let style = {
      textDecoration: "none",
    };

    if (clickable === false) {
      style["pointerEvents"] = "none";
    }

    return style;
  };

  let setContainerStyle = () => {
    let style = {};

    if (clickable === false) {
      style["cursor"] = "not-allowed";
    }

    return style;
  };
  return (
    <Tooltip title={title} arrow>
      <Navbar.Text style={setContainerStyle()} className="navbar-url">
        <Link
          data-testid={dataTestId}
          to={url}
          target={url.startsWith("/") || url === "" ? "" : "_blank"}
          style={setLinkStyle()}
          onClick={onClick}
        >
          <span className="mobile-navbar-url-text">
            {mobileUrlText ? mobileUrlText : urlText}
          </span>
          <span className="navbar-url-text">{urlText}</span>
        </Link>
      </Navbar.Text>
    </Tooltip>
  );
};

export default NavBarUrl;
