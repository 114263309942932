import instance from "../instance";
import axios from "axios";

export const addAudit = (body, source = axios.CancelToken.source()) => {
  let cancelToken = source.token;

  return instance.post(`/v1/reporting/audit/`, body, {
    cancelToken,
  });
};


export const addAuditWhiteList = (body, source = axios.CancelToken.source()) => {
  let cancelToken = source.token;

  return instance.put(`/v1/whitelist/audit/`, body, {
    cancelToken,
  });
};
