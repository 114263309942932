import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getAttachment } from "../../api/doc/attachmentApi";

const AttachmentViewer = () => {
  const { pageId, fileName } = useParams();
  const [file, setFile] = useState(null);
  const [downloadPercentage, setDownloadPercentage] = useState(0);
  const [isDownloadFailed, setIsDownloadFailed] = useState(false);

  document.body.setAttribute("style", "overflow:hidden");

  useEffect(() => {
    let onDownloadProgress = (e) => {
      let percentage = (e.loaded * 100) / e.total;
      percentage = percentage.toFixed(2);
      setDownloadPercentage(percentage);
    };

    getAttachment(`/v1/download/attachment/${pageId}/file/${fileName}`, (e) =>
      onDownloadProgress(e)
    )
      .then((response) => {
        if (response.status === 200) {
          let blob = response.data;

          setFile(window.URL.createObjectURL(blob));
        } else {
          setIsDownloadFailed(true);
        }
      })
      .catch((error) => {
        setIsDownloadFailed(true);
      });
  }, [pageId, fileName]);

  let render = () => {
    if (null !== file) {
      return (
        <embed
          title="iframe"
          src={file}
          type="application/pdf"
          width="100%"
          height="100%"
          style={{ overflow: "auto" }}
        ></embed>
      );
    } else if (isDownloadFailed === true) {
      return <h1>Download failed</h1>;
    } else {
      return <h1>PDF Loading: {downloadPercentage}%</h1>;
    }
  };

  return <>{render()}</>;
};

export default AttachmentViewer;
