import Constants from "./Constants";

export const changeVersionAction = (version) => {
  return {
    type: Constants.CHANGE_VERSION,
    payload: version,
  };
};

export const populateVersionsAction = (versions) => {
  return {
    type: Constants.POPULATE_VERSIONS,
    payload: versions,
  };
};
