import reduxStore from "../helpers/reduxStore";

export default class CookieService {
  static isInitialized = false;
  static isInitializing = false;

  static init = () => {
    return new Promise((resolve, reject) => {
      if (CookieService.isAttachmentViewerUrl()) {
        return reject();
      }

      if (
        reduxStore.getState().c &&
        !CookieService.isInitializing &&
        !CookieService.isInitialized
      ) {
        CookieService.isInitializing = true;

        let script = document.createElement("script");

        script.src = process.env.REACT_APP_COOKIE_SCRIPT_PATH;
        script.type = "text/javascript";

        document.head.appendChild(script);

        script.onload = () => {
          setTimeout(() => {
            let instance = window.CookieScript.init();
            if (
              !document.querySelector("#cookiescript_injected") &&
              !instance.currentState().action
            ) {
              window.CookieScript.init().show();
            }
          }, 500);

          CookieService.isInitialized = true;
          CookieService.isInitializing = false;
          resolve();
        };

        script.onerror = () => {
          reject();
        };
      } else {
        reject();
      }
    });
  };

  static showCookieScript = () => {
    if (CookieService.isInitialized) {
      window.CookieScript.init().show();
    }
  };

  static isAttachmentViewerUrl = () => {
    return window.location.pathname.startsWith("/download/attachment/");
  };
}
