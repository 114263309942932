import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const Navigate = ({
  preventScrollReset,
  relative,
  replace,
  state,
  withParams,
  to,
}) => {
  const navigate = useNavigate();

  useEffect(() => {
    let options = {};
    if (preventScrollReset !== undefined) {
      options["preventScrollReset"] = preventScrollReset;
    }

    if (relative !== undefined) {
      options["relative"] = relative;
    }

    if (replace !== undefined) {
      options["replace"] = replace;
    }
    if (state !== undefined) {
      options["state"] = state;
    }

    let newUrl = to;

    if (withParams === true && window.location.search) {
      newUrl += window.location.search;
    }

    navigate(newUrl, options);
  }, [navigate, preventScrollReset, relative, replace, state, withParams, to]);
  return <></>;
};

export default Navigate;
