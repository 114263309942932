/* /src/routes/privateRoute.jsx */
import React from "react";
import { connect } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";
import { updateConfigByRestApiAction } from "../../actions/configAction";
import { AuthConsumer } from "../AuthProvider";
import PageLayout from "../PageLayout";
import { Spinner } from "../utils";
import reduxStore from "../../helpers/reduxStore";
import { getPreviousPath } from "../../utils/functions";

const PrivateRouteWithoutRedux = ({ element, withLayout, config }) => {
  const Render = ({ element }) => {
    let location = useLocation();

    let isAdminRoute = () => {
      return location.pathname.startsWith("/admin");
    };

    let isConfidentialityManagementRoute = () => {
      return location.pathname.startsWith("/confidentiality-management");
    };
    return (
      <AuthConsumer>
        {({ isAuthenticated }) => {
          let isUserAuthenticated = isAuthenticated();
          if (!isUserAuthenticated) {
            return (
              <Navigate
                to={`/signin-trigger?sourceUrl=${encodeURIComponent(
                  window.location.pathname +
                    window.location.search +
                    window.location.hash
                )}`}
                replace={true}
              />
            );
          } else if (config === null) {
            reduxStore.dispatch(updateConfigByRestApiAction());
            return <Spinner />;
          } else if (
            (isAdminRoute() && !config?.ADMIN) ||
            (isConfidentialityManagementRoute() &&
              !config?.CONFIDENTIALITY_MANAGEMENT)
          ) {
            return (
              <Navigate
                to={"/accessDenied"}
                replace={true}
                state={{
                  prevPath: getPreviousPath(),
                }}
              />
            );
          } else {
            return <React.Fragment>{element}</React.Fragment>;
          }
        }}
      </AuthConsumer>
    );
  };

  // if config is null, we don't want to show the PageLayout, until we get the config
  if (withLayout === false || config === null) {
    return <Render element={element} />;
  }

  return (
    <PageLayout>
      <Render element={element} />
    </PageLayout>
  );
};

let mapStateToProps = (state) => {
  return {
    config: state.config,
  };
};

export const PrivateRoute = connect(
  mapStateToProps,
  {}
)(PrivateRouteWithoutRedux);
