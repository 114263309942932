export function register(config) {
  // The URL constructor is available in all browsers that support SW.
  const publicUrl = new URL(process.env.PUBLIC_URL, window.location.href);
  if (publicUrl.origin !== window.location.origin) {
    // Our service worker won't work if PUBLIC_URL is on a different origin
    // from what our page is served on. This might happen if a CDN is used to
    // serve assets; see https://github.com/facebook/create-react-app/issues/2374
    return;
  }

  window.addEventListener("load", () => {
    const swUrl = `${process.env.PUBLIC_URL}/service-worker.js`;

    let matchRegex = new RegExp(/\/download\/attachment\/\d*\/./);

    let href = window.location.href;

    if (!matchRegex.test(href)) {
      // Is not localhost. Just register service worker
      registerValidSW(swUrl, config);
    }
  });
}

function postAttachmentData(serviceWorker) {
  serviceWorker.postMessage({
    type: "INIT_ATTACHMENT_DATA",
    attachmentData: {
      userData: window.userData,
      backendUrl: process.env.REACT_APP_API_URL,
    },
  });
}

function registerValidSW(swUrl, config) {
  navigator.serviceWorker
    .register(swUrl)
    .then((registration) => {
      if (navigator.serviceWorker.controller === null) {
        // we get here after a ctrl+f5 OR if there was no previous service worker.
        registration.active?.postMessage("claimMe");
      }

      let serviceWorker =
        registration.active || registration.installing || registration.waiting;

      postAttachmentData(serviceWorker);

      document.addEventListener("USER_SAVED", () => {
        postAttachmentData(serviceWorker);
      });

      registration.onupdatefound = () => {
        const installingWorker = registration.installing;
        if (installingWorker == null) {
          return;
        }

        installingWorker.onstatechange = () => {
          if (installingWorker.state === "installed") {
            if (navigator.serviceWorker.controller) {
              // At this point, the updated precached content has been fetched,
              // but the previous service worker will still serve the older
              // content until all client tabs are closed.
              console.log(
                "New content is available and will be used when all " +
                  "tabs for this page are closed. See https://cra.link/PWA."
              );

              // Execute callback
              if (config?.onUpdate) {
                config.onUpdate(registration);
              }
            } else {
              // At this point, everything has been precached.
              // It's the perfect time to display a
              // "Content is cached for offline use." message.
              console.log("Content is cached for offline use.");

              // Execute callback
              if (config?.onSuccess) {
                config.onSuccess(registration);
              }
            }
          }
        };
      };
    })
    .catch((error) => {
      console.error("Error during service worker registration:", error);
    });
}
