import React, { useEffect } from "react"
import MessagePage from "../../components/Pages/MessagePage"

const RouteNotFoundPage =()=>{
  useEffect(() => {
    document.title = `Not Found | MX Official Documentation`;
  }, []);

    return <MessagePage
    title="404"
    description="Looks like you're lost"
    help="The route you are looking for is not available!"
  />
}


export default RouteNotFoundPage