import React, { Suspense, useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";

import {
  changeSidebarWidthAction,
  closeSideBarAction,
} from "../../actions/sideBarActions";
import "./DraggableDrawer.css";
import DraggableDrawerToggle from "./DraggableDrawerToggle";
import DragHandler from "./DragHandler";
import TreeView from "../TreeView";
import { useLocation } from "react-router-dom";
import { Spinner } from "../utils";

const NestedList = React.lazy(() => import("../NestedList"));

const DraggableDrawer = ({
  changeSidebarWidth,
  sidebarWidth,
  navbarHeight,
  isSideBarOpen,
  closeSideBar,
}) => {
  const location = useLocation();
  const [drawerDisplay, setDrawerDisplay] = useState("block");

  const isShowSideBar = useCallback(() => {
    return !location.pathname.startsWith("/confidentiality-management");
  }, [location]);

  useEffect(() => {
    if (!isShowSideBar()) {
      closeSideBar();
    }
  }, [isShowSideBar, closeSideBar]);

  useEffect(() => {
    let changeSidebarWidthCaller = () => {
      changeSidebarWidth();
    };
    window.addEventListener("resize", changeSidebarWidthCaller);

    return () => window.removeEventListener("resize", changeSidebarWidthCaller);
  }, [changeSidebarWidth]);

  useEffect(() => {
    if (isSideBarOpen) {
      setDrawerDisplay("block");
    } else {
      setDrawerDisplay("none");
    }
  }, [isSideBarOpen]);

  let stopEventDefaultBehavior = (e) => {
    if (e.stopPropagation) e.stopPropagation();
    if (e.preventDefault) e.preventDefault();
    e.cancelBubble = true;
    e.returnValue = false;
  };
  const handleMouseDown = (e) => {
    document.addEventListener("mouseup", handleMouseUp, true);
    document.addEventListener("mousemove", handleMouseMove, true);

    stopEventDefaultBehavior(e);
  };

  const handleMouseUp = (e) => {
    document.removeEventListener("mouseup", handleMouseUp, true);
    document.removeEventListener("mousemove", handleMouseMove, true);
    stopEventDefaultBehavior(e);
  };

  const handleMouseMove = useCallback(
    (e) => {
      const newWidth = e.clientX - document.body.offsetLeft + 4;
      changeSidebarWidth(newWidth);
      stopEventDefaultBehavior(e);
    },
    [changeSidebarWidth]
  );

  const renderDrawerContent = () => {
    if (location.pathname.startsWith("/admin")) {
      return (
        <div
          id="drawer-list"
          style={{
            height: `calc(100% - ${navbarHeight}px)`,
            paddingLeft: "0px",
            paddingRight: "0px",
          }}
        >
          <Suspense fallback={<Spinner />}>
            <NestedList />
          </Suspense>
        </div>
      );
    } else if (location.pathname.startsWith("/pages")) {
      return (
        <div
          id="drawer-tree"
          style={{
            height: `calc(100% - ${navbarHeight}px)`,
          }}
        >
          <TreeView />
        </div>
      );
    }
  };

  if (isShowSideBar()) {
    return (
      <React.Fragment>
        <div
          data-testid="draggableDrawer"
          id="draggable-drawer"
          style={{
            width: `${sidebarWidth}px`,
            display: drawerDisplay,
          }}
        >
          {renderDrawerContent()}
          <div
            id="draggable-bar"
            data-testid="draggableBar"
            onMouseDown={(e) => handleMouseDown(e)}
          >
            <div id="drag-handler-container" data-testid="dragHandlerContainer">
              <DragHandler />
              <DragHandler />
              <DragHandler />
            </div>
          </div>
        </div>
        <DraggableDrawerToggle />
      </React.Fragment>
    );
  }
};

let mapStateToProps = (state) => {
  return {
    sidebarWidth: state.sidebarWidth,
    isSideBarOpen: state.isSideBarOpen,
    navbarHeight: state.navbarHeight,
  };
};

export default connect(mapStateToProps, {
  changeSidebarWidth: changeSidebarWidthAction,
  closeSideBar: closeSideBarAction,
})(DraggableDrawer);
