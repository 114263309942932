import Constants from "../actions/Constants";

export const navbarHeightReducer = (height = 5, action) => {
  switch (action.type) {
    case Constants.CHANGE_NAVBAR_HEIGHT:
      let newHEight = action.payload;

      if (!newHEight) {
        newHEight = height;
      }

      return newHEight;
    default:
      return height;
  }
};
