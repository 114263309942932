import { sidebarWidthReducer, isSideBarOpenReducer } from "./sidebarReducers";
import { userReducer } from "./userReducers";
import { versionReducer, versionsReducer } from "./versionReducers";
import { treeReducer } from "./treeReducers";
import { combineReducers } from "redux";
import { pageReducer } from "./pageReducers";
import { cReducer } from "./cReducer";
import { configReducer } from "./configReducers";
import { searchReducer } from "./searchReducers";
import { navbarHeightReducer } from "./navbarReducers";

export default combineReducers({
  sidebarWidth: sidebarWidthReducer,
  isSideBarOpen: isSideBarOpenReducer,
  user: userReducer,
  version: versionReducer,
  tree: treeReducer,
  page: pageReducer,
  c: cReducer,
  config: configReducer,
  versions: versionsReducer,
  searchData: searchReducer,
  navbarHeight: navbarHeightReducer,
});
