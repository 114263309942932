import React from "react";
import { connect } from "react-redux";

const TopNavButton = ({ c, text, icon, onClick, testid }) => {
  return (
    <button
      className="top-nav-button"
      data-testid={"topNavButton" + (testid ? "-" + testid : "")}
      onClick={onClick}
    >
      <span className="top-nav-button-icon">{icon}</span>
      <span className="top-nav-button-text">{c === true ? text : ""}</span>
    </button>
  );
};

let mapStateToProps = (state) => {
  return {
    c: state.c,
  };
};

export default connect(mapStateToProps)(TopNavButton);
