// Import deps
import React, { useCallback, useEffect, useRef } from "react";
import { Navbar } from "react-bootstrap";

// Import styles
import "./TopNav.css";

import NavBarBrand from "./NavBarBrand";
import NavBarSearchContainer from "./NavBarSearchContainer";

// Import images
import logo from "../../assets/img/murex_logo.png";
import { useLocation } from "react-router-dom";
import AvatarMenu from "./NavBarAvatarActionContainer";
import { changeNavbarHeightAction } from "../../actions/navbarActions";
import { connect } from "react-redux";

function TopNav({ changeNavbarHeight, navbarHeight }) {
  const location = useLocation();
  let topNavRef = useRef(null);

  let setTopNavHeightAction = useCallback(() => {
    if (null !== topNavRef) {
      changeNavbarHeight(topNavRef.current?.clientHeight);
    }
  }, [changeNavbarHeight]);

  useEffect(() => {
    if (null === topNavRef || !topNavRef.current) {
      return;
    }

    const resizeObserver = new ResizeObserver(() => {
      setTopNavHeightAction();
    });
    resizeObserver.observe(topNavRef.current);

    return () => resizeObserver.disconnect(); // clean up
  }, [setTopNavHeightAction]);

  const showSearch = () => {
    if (
      !location.pathname.startsWith("/admin") &&
      !location.pathname.startsWith("/confidentiality-management")
    ) {
      return <NavBarSearchContainer />;
    }
  };

  return (
    <>
      <div style={{ height: `${navbarHeight}px` }}></div>
      <Navbar
        data-testid="topNav"
        ref={topNavRef}
        className="topNavBar me-auto align-items-center"
        collapseOnSelect
        expand="md"
        fixed="top"
        variant="dark"
        id="app-bar"
        style={{ zIndex: 1800 }}
      >
        <NavBarBrand
          title={
            location.pathname.startsWith("/admin")
              ? "MXDoc Admin Dashboard"
              : "MX Official Documentation"
          }
          logo={logo}
        />
        {showSearch()}
        <AvatarMenu />
      </Navbar>
    </>
  );
}

let mapStateToProps = (state) => {
  return {
    navbarHeight: state.navbarHeight,
  };
};

export default connect(mapStateToProps, {
  changeNavbarHeight: changeNavbarHeightAction,
})(TopNav);
