import React from "react";
import { TreeItem as MuiTreeItem } from "@mui/x-tree-view";
import { useNavigate } from "react-router-dom";
import { useQuery } from "../utils";
import AuditService from "../../services/AuditService";

const TreeItem = ({ data, children, onClick }) => {
  const navigate = useNavigate();
  const query = useQuery();

  let handleSelectPage = (pageId) => {
    navigate(`/pages/viewpage?pageId=${pageId}`);
    AuditService.addSideTreePageOpenEvent(pageId);
  };

  let getListTypeStyle = () => {
    if (
      (Array.isArray(data.children) && data.children.length === 0) ||
      !data.children
    ) {
      return "disc";
    }

    return "none";
  };

  // when the user clicks the text, we set it as selected, but we don't get the children
  // because when the user is redirected, we get the children
  let handleClick = (e, pageId) => {
    handleSelectPage(pageId);
  };

  return (
    <MuiTreeItem
      onClick={onClick}
      style={{ padding: "0.125rem 0 0.125rem 0" }}
      defaultChecked={data.pageId === query.get("pageId")}
      defaultValue={data.pageId === query.get("pageId")}
      key={data.pageId}
      nodeId={data.pageId}
      label={
        <div
          data-testid={`treeItem-${data.pageId}`}
          className="tree-item-wrapper"
          title={data.title}
          onClick={(e) => handleClick(e, data.pageId)}
          style={{
            listStyleType: getListTypeStyle(),
          }}
        >
          {data.title}
        </div>
      }
    >
      {children}
    </MuiTreeItem>
  );
};

export default TreeItem;
