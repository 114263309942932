import instance from "../instance";
import axios from "axios";

export const getAvailableVersions = (source = axios.CancelToken.source()) => {
  let cancelToken = source.token;

  return instance.get(`/v1/version/availableVersions`, {
    cancelToken,
  });
};

export const pinVersion = (versionId, source = axios.CancelToken.source()) => {
  let cancelToken = source.token;

  return instance.post(
    `/v1/version/pin/${versionId}`,
    {},
    {
      cancelToken,
    }
  );
};
