import { getConfig } from "../api/doc/configApi";
import Constants from "./Constants";

export const updateConfigAction = (config) => {
  return {
    type: Constants.UPDATE_CONFIG,
    payload: config,
  };
};

let isFetchingConfig = false;

export const updateConfigByRestApiAction = () => {
  return async (dispatch, getState) => {
    if (!isFetchingConfig) {
      isFetchingConfig = true;
      let config = await getConfig();

      let data = config.data;

      dispatch(updateConfigAction(data));
      isFetchingConfig = false;
    }
  };
};
