import React, { useCallback, useEffect, useState } from "react";
import { TreeView } from "@mui/x-tree-view";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

import { useQuery } from "../utils";
import TreeItem from "./TreeItem";

import "./TreeView.css";
import { connect } from "react-redux";
import { getTreeByVersion } from "../../api/doc/treeApi";
import { updateTreeAction } from "../../actions/treeActions";
import axios from "axios";
import { getAllParentOfPage, setPageChildren } from "../../utils/treeUtils";
import Constants from "../../actions/Constants";
import { useLocation } from "react-router-dom";
import AuditService from "../../services/AuditService";

const TreeViewComponent = ({ version, tree, updateTree }) => {
  const location = useLocation();
  const query = useQuery();

  const [treeComponents, setTreeComponents] = useState(null);
  const [expanded, setExpanded] = useState([query.get("pageId")]);
  const [selected, setSelected] = useState([]);
  const [queryPageId, setQueryPageId] = useState(null);

  const renderTree = useCallback(
    (data) => {
      if (!data) {
        return;
      }
      // dont take the first page
      if (!tree.childParentMap[data.pageId] || data.pageId === "0") {
        if (Array.isArray(data.children)) {
          return data.children
            .sort((x, y) => x.position - y.position)
            .map((node) => renderTree(node));
        }

        return null;
      }

      return (
        <TreeItem
          key={data.pageId}
          data={data}
          onClick={() => setPageChildren(data.pageId, tree)}
        >
          {Array.isArray(data.children)
            ? data.children
                .sort((x, y) => x.position - y.position)
                .map((node) => renderTree(node))
            : data.hasChildren
            ? "Loading..."
            : null}
        </TreeItem>
      );
    },
    [tree]
  );

  useEffect(() => {
    // if the url changed, which means the user did not click on the arrow to get the page children
    if (tree.childParentMap[queryPageId]) {
      setPageChildren(queryPageId, tree);
    }
  }, [queryPageId, tree]);

  // when tree data is updated, rerender the tree
  useEffect(() => {
    let newRenderTree = renderTree(JSON.parse(JSON.stringify(tree.data)));

    setTreeComponents(newRenderTree);
  }, [renderTree, tree]);

  // update the tree on version change
  useEffect(() => {
    let source = axios.CancelToken.source();

    if (version.value) {
      let getTreeData = async () => {
        try {
          // if the selected version not equal to the current tree version, and there is no id, then we need to get the tree by version
          if (
            version.value !== tree.version &&
            (location.pathname !== "/pages/viewpage" || !queryPageId)
          ) {
            let versionResponse = await getTreeByVersion(version.value);
            let data = versionResponse.data;

            updateTree({
              version: version.value,
              data,
              source: Constants.SOURCE_VERSION_TREE_UPDATE,
            });
          }
        } catch (error) {
          if (error.name !== "CanceledError") {
            updateTree({
              version: version.value,
              data: {},
              source: Constants.SOURCE_EMPTY_TREE_UPDATE,
            });
          }
        }
      };

      getTreeData();
    }

    return () => {
      source.cancel();
    };
  }, [version, updateTree, queryPageId, tree.version, location.pathname]);

  // set the selected pageId in tree
  useEffect(() => {
    let currentPageId = query.get("pageId");
    if (currentPageId !== queryPageId) {
      setQueryPageId(currentPageId);
      setSelected(currentPageId);
      setExpanded((prevState) => {
        prevState.push(currentPageId);
        prevState = [...new Set(prevState)];
        return prevState;
      });
    }
  }, [query, queryPageId]);

  // update the expanded nodes on page change
  useEffect(() => {
    // if page inside the tree and the source of the tree update is a reverse tree update
    // then we are sure that we need to render all the parents of the queryPageId
    if (
      tree.childParentMap[queryPageId] !== undefined &&
      tree.source === Constants.SOURCE_REVERSE_TREE_UPDATE
    ) {
      let parentsPageIds = getAllParentOfPage(queryPageId, tree.childParentMap);

      setExpanded((prevState) => {
        if (tree.version !== undefined) {
          prevState = prevState.filter((id) => {
            return (
              id === queryPageId || // if the id is the currently selected id, keep it
              (tree.childParentMap[id] !== undefined &&
                tree.parentChildrenMap[id].length > 0) // if the id is inside the tree and has children
            );
          });
        }
        parentsPageIds.push(...prevState);
        parentsPageIds = [...new Set(parentsPageIds)];
        return parentsPageIds;
      });
    }
  }, [queryPageId, tree]);

  const handleToggle = (event, nodeIds) => {
    if (nodeIds.length > expanded.length) {
      AuditService.addSideTreeNodeExpandEvent(nodeIds[0]);
    } else {
      let collapsedNodeId = expanded.filter((nodeId) => {
        return !nodeIds.includes(nodeId);
      });
      AuditService.addSideTreeNodeCollapseEvent(collapsedNodeId[0]);
    }

    setExpanded(nodeIds);
  };

  const handleSelect = (event, nodeId) => {
    setSelected(queryPageId);
  };

  return (
    <TreeView
      data-testid="treeView"
      aria-label="controlled"
      defaultCollapseIcon={<ExpandMoreIcon />}
      defaultExpandIcon={<ChevronRightIcon />}
      selected={selected}
      expanded={expanded}
      onNodeToggle={handleToggle}
      onNodeSelect={handleSelect}
      sx={{ height: "100%", flexGrow: 1, maxWidth: "100%" }}
    >
      {treeComponents}
    </TreeView>
  );
};

let mapStateToProps = (state) => {
  return {
    version: state.version,
    tree: state.tree,
  };
};

export default connect(mapStateToProps, {
  updateTree: updateTreeAction,
})(TreeViewComponent);
