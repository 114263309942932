import Constants from "../actions/Constants";

const KEY_DRAWER_WIDTH = "drawer_width";
const KEY_DRAWER_OPEN = "drawer_open";

let isDrawerOpen = () => {
  return localStorage.getItem(KEY_DRAWER_OPEN) === "true";
};

let getInitWidth = () => {
  let isOpen = isDrawerOpen() || !localStorage.getItem(KEY_DRAWER_OPEN);

  if (!isOpen) {
    return 0;
  } else if (localStorage.getItem(KEY_DRAWER_WIDTH)) {
    return Number(localStorage.getItem(KEY_DRAWER_WIDTH));
  } else {
    return 285;
  }
};

export const sidebarWidthReducer = (width = getInitWidth(), action) => {
  const maxWidth = (document.body.clientWidth * 60) / 100; // 60% of the total width
  const minWidth = 250;
  let newWidth = 0;

  switch (action.type) {
    case Constants.CHANGE_SIDEBAR_WIDTH:
      newWidth = action.payload;

      if (!newWidth) {
        newWidth = width;
      }

      if (!isDrawerOpen()) {
        return 0;
      }

      if (newWidth > maxWidth) {
        newWidth = maxWidth;
      } else if (newWidth <= minWidth) {
        newWidth = minWidth;
      }

      localStorage.setItem(KEY_DRAWER_WIDTH, newWidth);
      return newWidth;
    case Constants.OPEN_SIDEBAR:
      newWidth = localStorage.getItem(KEY_DRAWER_WIDTH);
      if (!newWidth) {
        newWidth = minWidth;
      } else {
        newWidth = Number(newWidth);
      }
      return newWidth;
    case Constants.CLOSE_SIDEBAR:
      if (isDrawerOpen()) {
        localStorage.setItem(KEY_DRAWER_WIDTH, width);
      }
      return 0;
    default:
      return width;
  }
};

export const isSideBarOpenReducer = (
  isSideBarOpen = localStorage.getItem(KEY_DRAWER_OPEN) ? isDrawerOpen() : true,
  action
) => {
  let isOpen = false;
  switch (action.type) {
    case Constants.OPEN_SIDEBAR:
      isOpen = true;
      break;
    case Constants.CLOSE_SIDEBAR:
      break;
    default:
      isOpen = isSideBarOpen;
  }

  localStorage.setItem(KEY_DRAWER_OPEN, isOpen);
  return isOpen;
};
