export const getSearchMap = () => {
  let queryString = window.location.search;
  let query = {};
  let pairs = (
    queryString[0] === "?" ? queryString.substring(1) : queryString
  ).split("&");
  for (let pair of pairs) {
    let pairArr = pair.split("=");
    query[decodeURIComponent(pairArr[0])] = decodeURIComponent(
      pairArr[1] || ""
    );
  }
  return query;
};

export const getPreviousPath = () => {
  return window.location.pathname + window.location.search;
};

export const scrollJustAboveTop = (element) => {
  let targetScrollPosition =
    element.getBoundingClientRect().top + window.scrollY - 100;
  window.scrollTo({
    top: targetScrollPosition,
    behavior: "smooth",
  });
};
