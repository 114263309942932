import instance from "../instance";
import axios from "axios";

export const updateAccount = (data, source = axios.CancelToken.source()) => {
  let cancelToken = source.token;

  return instance.put("/v1/account/update", data, {
    cancelToken,
  });
};
