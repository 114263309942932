const Constants = {
  CHANGE_NAVBAR_HEIGHT: "CHANGE_NAVBAR_HEIGHT",
  CHANGE_SIDEBAR_WIDTH: "CHANGE_SIDEBAR_WIDTH",
  OPEN_SIDEBAR: "OPEN_SIDEBAR",
  CLOSE_SIDEBAR: "CLOSE_SIDEBAR",
  ADD_USER: "ADD_USER",
  DELETE_USER: "DELETE_USER",
  CHANGE_VERSION: "CHANGE_VERSION",
  POPULATE_VERSIONS: "POPULATE_VERSIONS",
  UPDATE_TREE: "UPDATE_TREE",
  UPDATE_PAGE: "UPDATE_PAGE",
  SOURCE_REVERSE_TREE_UPDATE: "SOURCE_REVERSE_TREE_UPDATE",
  SOURCE_VERSION_TREE_UPDATE: "SOURCE_VERSION_TREE_UPDATE",
  SOURCE_SUB_TREE_UPDATE: "SOURCE_SUB_TREE_UPDATE",
  SOURCE_EMPTY_TREE_UPDATE: "SOURCE_EMPTY_TREE_UPDATE",
  UPDATE_C: "UPDATE_C",
  UPDATE_CONFIG: "UPDATE_CONFIG",
  CHANGE_SEARCH_DATA: "CHANGE_SEARCH_DATA",
  SOURCE_NOT_AVAILABLE: "SOURCE_NOT_AVAILABLE",
  SOURCE_NOT_FOUND: "SOURCE_NOT_FOUND",
  SOURCE_ACCESS_DENIED: "SOURCE_ACCESS_DENIED",
  SOURCE_DONT_UPDATE_TREE: "SOURCE_DONT_UPDATE_TREE",
};

export default Constants;
