import { configureStore } from "@reduxjs/toolkit";
import reducers from "../reducers";

// this middleware is used so we will be able to run dispatch methods inside a reducer
const asyncDispatchMiddleware = (currentStore) => (next) => (action) => {
  let syncActivityFinished = false;
  let actionQueue = [];

  function flushQueue() {
    actionQueue.forEach((a) => currentStore.dispatch(a)); // flush queue
    actionQueue = [];
  }

  function asyncDispatch(asyncAction) {
    actionQueue = actionQueue.concat([asyncAction]);

    if (syncActivityFinished) {
      flushQueue();
    }
  }

  const actionWithAsyncDispatch = Object.assign({}, action, { asyncDispatch });

  next(actionWithAsyncDispatch);
  syncActivityFinished = true;
  flushQueue();
};

const preloadedState = {};
const store = configureStore({
  reducer: reducers,
  preloadedState,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(asyncDispatchMiddleware),
});

export const setupStore = (customPreloadedState) => {
  return configureStore({
    reducer: reducers,
    preloadedState: customPreloadedState,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat(asyncDispatchMiddleware),
  });
};

export default store;
