import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { Spinner } from "../../components/utils";
import { connect } from "react-redux";
import { getPreviousPath } from "../../utils/functions";

const ConfluenceDisplayUrl = ({ versions }) => {
  const { confluenceSpace, confluenceTitle } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (versions.length !== 0) {
      let versionName = confluenceSpace.replace("MXDOCV", "");
      let title = confluenceTitle.replaceAll("+", " ");
      let versionsResult = versions.filter(
        (currentVersion) => currentVersion.name === versionName
      );
      if (versionsResult.length > 0) {
        navigate(
          `/pages/findpage?versionId=${versionsResult[0]?.id}&title=${title}`,
          {
            replace: true,
            state: {
              prevPath: getPreviousPath(),
            },
          }
        );
      } else {
        navigate(`/pageNotFound`, {
          replace: true,
          state: {
            prevPath: getPreviousPath(),
          },
        });
      }
    }
  }, [confluenceSpace, confluenceTitle, versions, navigate]);
  return <Spinner />;
};

let mapStateToProps = (state) => {
  return {
    versions: state.versions,
  };
};

export default connect(mapStateToProps, {})(ConfluenceDisplayUrl);
