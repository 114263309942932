import CookieService from "../services/CookieService";
import MatomoService from "../services/MatomoService";
import TcService from "../services/TcService";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

export const initServiceWorker = () => {
  serviceWorkerRegistration.register();
};

export const initMathjax = () => {
  function loadMathjaxConfig() {
    window.MathJax.Hub.Config({
      extensions: ["tex2jax.js", "TeX/AMSmath.js"],
      jax: ["input/TeX", "output/HTML-CSS", "output/NativeMML"],
      tex2jax: {
        processEscapes: true,
      },
      CommonHTML: { linebreaks: { automatic: true } },
      SVG: { linebreaks: { automatic: true } },
      "HTML-CSS": { linebreaks: { automatic: true } },
      messageStyle: "none",
      TeX: {
        noErrors: { disabled: true },
        Macros: {
          textcolor: ["{\\color{#1}{#2}}", 2],
          textnormal: ["\\text{#1}", 1],
          textgreater: ">",
          textless: "<",
          bm: ["\\boldsymbol{#1}", 1],
          bold: ["\\boldsymbol{#1}", 1],
          "-": "-",
          hdots: "\\dots",
          Chi: "X",
          Tau: "T",
          Mu: "M",
          Nu: "N",
          Alpha: "A",
          Beta: "B",
          Zeta: "Z",
          Epsilon: "E",
          Eta: "H",
          Iota: "I",
          Kappa: "K",
          Rho: "P",
          coloneqq: "\\mathrel{\\vcenter{:}}=",
          "~": "\\text{~}",
          textup: ["\\mathrm{#1}", 1],
          thickspace: "\\;",
          textsl: ["\\textit{#1}", 1],
          textsuperscript: ["^{#1}", 1],
          o: "ø",
          textunderscore: "\\_",
          texttildelow: "\\~",
          textasciitilde: "\\~",
          section: ["{\\LARGE #1}", 1],
          subsection: ["{\\Large #1}", 1],
          boldmath: ["\\bold{#1}", 1],
          linebreak: "\\\\",
          newline: "\\\\",
          textmd: ["\\text{#1}", 1],
          underbar: ["\\underline{#1}", 1],
          textbackslash: "\\text{\\}",
          vspace: ["\\\\[#1]", 1],
          math: ["\\( #1 \\)", 1],
          textsubscript: ["_{\\text{#1}}", 1],
          nobreak: ["#1", 1],
          par: "\\\\",
          l: "ł",
          L: "Ł",
          "=": ["\\overline{#1}", 1],
          "[": "\\begin{eqnarray}",
          "]": "\\end{eqnarray}",
          "(": "$",
          ")": "$",
          "^": ["\\hat{#1}", 1],
          emph: ["#1", 1],
          centering: "",
        },
      },
    });
  }
  function addMathjaxLib() {
    let script = document.createElement("script");
    script.setAttribute(
      "src",
      "/js/lib/mathjax/MathJax.js?config=TeX-MML-AM_CHTML"
    );
    script.setAttribute("type", "text/javascript");
    script.setAttribute("charset", "UTF-8");
    script.onload = loadMathjaxConfig;
    document.querySelector("head").appendChild(script);
  }

  function init() {
    let script = document.createElement("script");
    script.setAttribute(
      "src",
      "https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.9/MathJax.js?config=TeX-MML-AM_CHTML"
    );

    script.onload = loadMathjaxConfig;
    script.onerror = addMathjaxLib;
    document.querySelector("head").appendChild(script);
  }
  init();
};

export const initStopCopy = (c) => {
  if (c) {
    document.addEventListener("copy", (e) => {
      console.log("Copy not authorized");
      e.preventDefault();
    });

    document.addEventListener("cut", (e) => {
      console.log("Cut not authorized");
      e.preventDefault();
    });
  }
};

export const initStopPrinting = (c) => {
  if (c) {
    let styleTag = document.createElement("style");

    styleTag.innerHTML = `
    @media print {
      body {
        display: none;  /* hide whole page */
        visibility:hidden;
      }
    }`;

    document.body.appendChild(styleTag);
  }
};

export const initCookieScript = () => {
  CookieService.init()
    .then(() => {})
    .catch((err) => {});
};

export const initTc = async () => {
  try {
    await TcService.init();
  } catch (error) {
    if (error?.target?.src === process.env.REACT_APP_TC_PLUGIN_JS_URL) {
      throw error;
    }
  }
};

export const initMatomo = () => {
  MatomoService.init()
    .then(() => {})
    .catch((err) => {});
};

export const initAccountPopupEventListener = (c, userAccounts) => {
  if (c && Object.keys(userAccounts ?? []).length > 1) {
    document.addEventListener("click", (e) => {
      const localStorageAccountId = localStorage.getItem("accountId");
      const dialogContentBackdrop = document.getElementById(
        "account-dialog-backdrop"
      );
      const dialogContent = document.getElementById("account-dialog-root");
      const cookieScript = document.getElementById("cookiescript_injected");
      const warningSnackbar = document.getElementById("snackbar-error");
      const tcPopup = document.getElementById("tc-popup");
      let isTcPopup =
        tcPopup?.contains(e.target) || e.target.id === "tc-accept-btn";
      let isModal = e.target.classList.contains("modal-message-presentation");
      if (
        (!localStorageAccountId || !dialogContentBackdrop) &&
        !dialogContent?.contains(e.target) &&
        !cookieScript?.contains(e.target) &&
        !warningSnackbar?.contains(e.target) &&
        !isTcPopup &&
        !isModal
      ) {
        window.location.reload();
      }
    });
  }
};
