import React, { useEffect } from "react";
import { AuthConsumer } from "../../components/AuthProvider";
import { Card } from "react-bootstrap";

import murexLogo from "../../assets/img/murex_colored_logo.svg";
import { useNavigate } from "react-router-dom";

const LogoutPage = () => {
  const navigate = useNavigate ();

  useEffect(() => {
    document.title = `Logout | MX Official Documentation`;
  }, []);

  let handleReloginClick = () => {
    navigate("/signin-trigger");
  };

  return (
    <AuthConsumer>
      {({ signoutFromApp }) => {
        signoutFromApp();
        return (
          <Card className="mx-auto w-50" style={{ marginTop: "15%" }}>
            <Card.Header
              className="text-center fs-2 text-white"
              style={{
                background:
                  "linear-gradient(to right, #db0a5b 0%, #582c83 100%)",
              }}
            >
              Logged off successfully
            </Card.Header>
            <Card.Body className="text-center">
              <img
                src={murexLogo}
                alt="murex logo"
                style={{ minWidth: "50%" }}
              />
              <p>
                You signed out from your account
                <br />
                It is a good idea to close your browser window
              </p>
              <button
                type="button"
                className="btn text-white"
                style={{ background: "rgb(219, 10, 91)" }}
                onClick={handleReloginClick}
              >
                Relogin
              </button>
            </Card.Body>
          </Card>
        );
      }}
    </AuthConsumer>
  );
};

export default LogoutPage;
