import React, { useEffect, useState } from "react";

import logo from "../../assets/img/murex_colored_logo.svg";

import "./MurexMessageModal.css";

const MurexMessageModal = ({
  display = false,
  message = "",
  allowClose = true,
  closeButtonText = "Ok",
  width = "700px",
  height = "auto",
  maxHeight = "75%",
  stopScrolling = false,
}) => {
  const [show, setShow] = useState(display);

  useEffect(() => {
    if (stopScrolling) {
      document.body.style.overflow = "hidden";
    }
  }, [stopScrolling]);

  const handleClose = () => {
    setShow(false);
    if (stopScrolling) {
      document.body.style.overflow = "";
    }
  };

  const showCloseButton = () => {
    if (allowClose) {
      return (
        <button onClick={handleClose} className="modal-message-close-button">
          {closeButtonText}
        </button>
      );
    }
  };

  if (show) {
    return (
      <div className="modal-message-presentation">
        <div
          className="modal-message-main-content"
          style={{ width, height, maxHeight }}
        >
          <div className="modal-message-header">
            <img src={logo} alt="Murex Logo" />
          </div>
          <div
            className="modal-message-content"
            dangerouslySetInnerHTML={{ __html: message }}
          ></div>
          <div className="modal-message-footer">{showCloseButton()}</div>
        </div>
      </div>
    );
  } else {
    return <></>;
  }
};

export default MurexMessageModal;
