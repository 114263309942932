import { UserManager, WebStorageStateStore } from "oidc-client-ts";

import reduxStore from "../helpers/reduxStore";
import { addUserAction, deleteUserAction } from "../actions/UserActions";
import { getOnBehalfOfToken } from "../api/doc/mindbreeze";
import { updateCAction } from "../actions/cAction";
import MindbreezeService from "./MindbreezeService";
import { updateAccountId } from "../utils/accountId";

export default class AuthService {
  userManager;
  static userStorageKey = `oidc.user:${process.env.REACT_APP_AUTHORITY}:${process.env.REACT_APP_CLIENT_ID}`;
  static userMindbreezeTokenKey = `mindbreeze.user:${process.env.REACT_APP_AUTHORITY}:${process.env.REACT_APP_CLIENT_ID}`;

  constructor() {
    const store = new WebStorageStateStore({ store: localStorage });

    this.userManager = new UserManager({
      authority: process.env.REACT_APP_AUTHORITY,
      client_id: process.env.REACT_APP_CLIENT_ID,
      redirect_uri: window.location.origin + "/signin",
      scope: process.env.REACT_APP_SCOPE,
      metadataUrl: process.env.REACT_APP_METADATA_URL,
      post_logout_redirect_uri: window.location.origin + "/logout",
      userStore: store,
    });

    this.userManager.events.addUserLoaded(async (user) => {
      this.saveUserInRedux(user);
      await this.updateAccountId();
      this.saveMindbreezeToken();
    });

    this.userManager.events.addSilentRenewError((e) => {
      this.signinRedirect();
    });

    this.userManager.events.addAccessTokenExpired(() => {
      this.signinSilent();
    });

    if (this.isAuthenticated()) {
      this.getUser().then((user) => {
        this.saveUserInRedux(user);
      });
    }
  }

  saveUserInRedux = (user) => {
    let preferredUsername = user.profile.preferred_username;

    if (
      preferredUsername.endsWith("@murex.com") ||
      preferredUsername.endsWith("@ext-murex.com")
    ) {
      reduxStore.dispatch(updateCAction(false));
    } else {
      reduxStore.dispatch(updateCAction(true));
    }

    let userToSave = {
      access_token: user.access_token,
      expires_at: user.expires_at,
      id_token: user.id_token,
      profile: user.profile,
      refresh_token: user.refresh_token,
      expires_in: user.expires_in,
      expired: user.expired,
    };

    window.userData = userToSave;

    reduxStore.dispatch(addUserAction(userToSave));
    document.dispatchEvent(new CustomEvent("USER_SAVED"));
  };

  saveMindbreezeToken = () => {
    getOnBehalfOfToken()
      .then((response) => {
        let data = JSON.stringify(response.data);
        localStorage.setItem(AuthService.userMindbreezeTokenKey, data);
        this.initializeMindbreeze();
      })
      .catch((error) => {
        console.error(error);
        console.error("Error while getting Mindbreeze token");
      });
  };

  initializeMindbreeze = () => {
    if (MindbreezeService.isScriptAdded && MindbreezeService.app) {
      let mindbreezeConfig = AuthService.getMindbreezeTokenFromStorage();
      MindbreezeService.app.options.apiRequestHeaders.Authorization = `Bearer ${
        mindbreezeConfig ? mindbreezeConfig.access_token : ""
      }`;
    }
  };

  deleteUserInRedux = () => {
    reduxStore.dispatch(deleteUserAction());
    window.userData = {};
  };

  getUser = async () => {
    const user = await this.userManager.getUser();
    if (!user) {
      return await this.userManager.signinRedirectCallback();
    }
    return user;
  };

  signinRedirectCallback = async () => {
    let user = await this.userManager.signinRedirectCallback();
    return user;
  };

  signinRedirect = (sourceUrl = "/") => {
    let state = { sourceUrl };

    this.userManager.signinRedirect({
      state,
    });
  };

  signinSilent = async () => {
    try {
      let user = await this.userManager.signinSilent();
      return user;
    } catch (error) {
      this.signinRedirect();
    }
  };

  updateAccountId = async () => {
    try {
      const accountId = localStorage.getItem("accountId");
      if (accountId) {
        await updateAccountId(accountId);
      }
    } catch (error) {
      localStorage.removeItem("accountId");
    }
  };

  signinSilentCallback = () => {
    this.userManager.signinSilentCallback();
  };

  logout = () => {
    this.deleteUserInRedux();
    this.userManager.signoutRedirect();
    this.userManager.clearStaleState();
  };

  signoutRedirectCallback = async () => {
    await this.userManager.signoutRedirectCallback();
    await this.userManager.clearStaleState();
  };

  signoutFromApp = async () => {
    localStorage.removeItem(AuthService.userStorageKey);
    localStorage.removeItem(AuthService.userMindbreezeTokenKey);
    this.deleteUserInRedux();
    await this.userManager.signoutRedirectCallback();
    await this.userManager.clearStaleState();
  };

  isAuthenticated = () => {
    const oidcStorage = AuthService.getUserFromStorage();

    return (
      oidcStorage &&
      oidcStorage.access_token &&
      oidcStorage.expires_at &&
      oidcStorage.expires_at * 1000 >= new Date().getTime()
    );
  };

  static getUserFromStorage = () => {
    return JSON.parse(localStorage.getItem(AuthService.userStorageKey));
  };

  static getMindbreezeTokenFromStorage = () => {
    return JSON.parse(localStorage.getItem(AuthService.userMindbreezeTokenKey));
  };
}
