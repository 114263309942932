import {Navigate, Route, Routes} from "react-router-dom";
import React, {Suspense} from "react";
import {Spinner} from "../components/utils";

const Search = React.lazy(() => import("../views/doc/Search"));
const Documentation = React.lazy(() => import("../views/doc/Documentation"));
const FindPage = React.lazy(() => import("../views/doc/FindPage"));

const DocRoutes = (props) => {
  return (
    <Routes>
      <Route
        path="/"
        element={<Navigate to="/viewpage" replace={true}/>}
      />

      <Route
        path="/viewpage"
        element={
          <Suspense fallback={<Spinner/>}>
            <Documentation {...props} />
          </Suspense>
        }
      />

      <Route
        path="/findpage"
        element={
          <Suspense fallback={<Spinner/>}>
            <FindPage {...props} />
          </Suspense>
        }
      />

      <Route
        path="/search"
        element={
          <Suspense fallback={<Spinner/>}>
            <Search/>
          </Suspense>
        }
      />

    </Routes>
  );
}

export default DocRoutes;