import React from "react";
import { useNavigate } from "react-router-dom";
import { AuthConsumer } from "../../components/AuthProvider";
import { Spinner } from "../../components/utils";

const SigninRedirectCallbackPage = () => {
  const navigate = useNavigate();

  return (
    <AuthConsumer>
      {({ signinRedirectCallback }) => {
        signinRedirectCallback()
          .then((user) => {
            if (user.state?.sourceUrl) {
              navigate(user.state.sourceUrl);
            } else {
              navigate("/");
            }
          })
          .catch((err) => {});

        return <Spinner />;
      }}
    </AuthConsumer>
  );
};

export default SigninRedirectCallbackPage;
