import { Navigate, Route, Routes } from "react-router-dom";
import React, { Suspense, useEffect } from "react";
import { Spinner } from "../components/utils";
import ErrorPage from "../views/common/ErrorPage";

const RouteNotFoundPage = React.lazy(() =>
  import("../views/common/RouteNotFoundPage")
);
const AuditDisplayPage = React.lazy(() =>
  import("../views/admin/AuditDisplay/AuditDisplayPage")
);
const ConfigManagementPage = React.lazy(() =>
  import("../views/admin/ConfigManagement/ConfigManagementPage")
);
const ConfigManagementAddPage = React.lazy(() =>
  import("../views/admin/ConfigManagement/ConfigManagementAddPage")
);
const ConfigManagementUpdatePage = React.lazy(() =>
  import("../views/admin/ConfigManagement/ConfigManagementUpdatePage")
);
const DocumentDashboardPage = React.lazy(() =>
  import("../views/admin/DocumentDashboard/DocumentDashboardPage")
);
const PermissionGroupPage = React.lazy(() =>
  import("../views/admin/PermissionManagement/PermissionGroupPage")
);
const PermissionGroupAddPage = React.lazy(() =>
  import("../views/admin/PermissionManagement/PermissionGroupAddPage")
);
const PermissionGroupUpdatePage = React.lazy(() =>
  import("../views/admin/PermissionManagement/PermissionGroupUpdatePage")
);
const GroupSyncPage = React.lazy(() =>
  import("../views/admin/SyncManagement/GroupSyncPage")
);
const VersionManagementUpdate = React.lazy(() =>
  import("../views/admin/VersionManagement/VersionManagementUpdate")
);
const VersionManagement = React.lazy(() =>
  import("../views/admin/VersionManagement/VersionManagement")
);
const PublishVersionPage = React.lazy(() =>
  import("../views/admin/SyncManagement/PublishVersionPage")
);
const HealthCheck = React.lazy(() => import("../views/admin/HealthCheck"));

const AdminRoutes = (props) => {
  useEffect(() => {
    document.title = "MXDoc Admin Dashboard";
  }, []);

  return (
    <Routes>
      <Route
        path="/"
        element={<Navigate to="/admin/version-management" replace={true} />}
      />

      <Route
        path="/audit-display"
        element={
          <Suspense fallback={<Spinner />}>
            <AuditDisplayPage {...props} />
          </Suspense>
        }
      />

      <Route
        path="/config-management/:availability"
        element={
          <Suspense fallback={<Spinner />}>
            <ConfigManagementPage {...props} />
          </Suspense>
        }
      />

      <Route
        path="/config-management/:availability/add"
        element={
          <Suspense fallback={<Spinner />}>
            <ConfigManagementAddPage {...props} />
          </Suspense>
        }
      />

      <Route
        path="/config-management/:availability/update/:configKey"
        element={
          <Suspense fallback={<Spinner />}>
            <ConfigManagementUpdatePage {...props} />
          </Suspense>
        }
      />

      <Route
        path="/document-dashboard"
        element={
          <Suspense fallback={<Spinner />}>
            <DocumentDashboardPage {...props} />
          </Suspense>
        }
      />

      <Route
        path="/permission-management"
        element={
          <Suspense fallback={<Spinner />}>
            <PermissionGroupPage {...props} />
          </Suspense>
        }
      />

      <Route
        path="/permission-management/add"
        element={
          <Suspense fallback={<Spinner />}>
            <PermissionGroupAddPage {...props} />
          </Suspense>
        }
      />

      <Route
        path="/permission-management/update/:groupId"
        element={
          <Suspense fallback={<Spinner />}>
            <PermissionGroupUpdatePage {...props} />
          </Suspense>
        }
      />

      <Route
        path="/sync-management/group"
        element={
          <Suspense fallback={<Spinner />}>
            <GroupSyncPage {...props} />
          </Suspense>
        }
      />

      <Route
        path="/sync-management/version"
        element={
          <Suspense fallback={<Spinner />}>
            <PublishVersionPage {...props} />
          </Suspense>
        }
      />

      <Route
        path="/version-management"
        element={
          <Suspense fallback={<Spinner />}>
            <VersionManagement {...props} />
          </Suspense>
        }
      />

      <Route
        path="/version-management/update/:versionId"
        element={
          <Suspense fallback={<Spinner />}>
            <VersionManagementUpdate {...props} />
          </Suspense>
        }
      />

      <Route
        path="/health-check"
        element={
          <Suspense fallback={<Spinner />}>
            <HealthCheck {...props} />
          </Suspense>
        }
      />

      <Route
        path="/pageNotFound"
        element={
          <Suspense fallback={<Spinner />}>
            <RouteNotFoundPage />
          </Suspense>
        }
      />

      <Route path="/error" element={<ErrorPage />} />

      <Route path="*" element={<Navigate to="/admin/pageNotFound" />} />
    </Routes>
  );
};

export default AdminRoutes;
