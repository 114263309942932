import React from "react";
import { AuthConsumer } from "../../components/AuthProvider";
import { Spinner, useQuery } from "../../components/utils";

const SigninTriggerPage = () => {
  let query = useQuery();
  return (
    <AuthConsumer>
      {({ signinRedirect }) => {
        signinRedirect(query.get("sourceUrl"));
        return <Spinner />;
      }}
    </AuthConsumer>
  );
};

export default SigninTriggerPage;
