import React, { Suspense, useEffect, useRef, useState } from "react";
import Select from "react-select";
import { connect } from "react-redux";
import {
  changeVersionAction,
  populateVersionsAction,
} from "../../../actions/versionActions";
import { getAvailableVersions, pinVersion } from "../../../api/doc/versionsApi";
import { useLocation } from "react-router-dom";
import { useQuery } from "../../utils";
import "../TopNav.css";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const OptionLabel = React.lazy(() => import("./OptionLabelWithPin"));
const SuccessAlert = React.lazy(() =>
  import("../../AlertSnackbar/AlertSnackbar")
);
const ErrorModal = React.lazy(() => import("../../Modals/ErrorModal"));

const customStyles = {
  menuList: (provided) => ({
    ...provided,
    '::-webkit-scrollbar': {
      width: '0.75rem',
    },
    '::-webkit-scrollbar-track': {
      background: '#f1f1f1',
    },
    '::-webkit-scrollbar-thumb': {
      backgroundColor: '#888',
      borderRadius: '0.625rem',
      border: '3px solid #f1f1f1',
    },
    '::-webkit-scrollbar-thumb:hover': {
      backgroundColor: '#555',
    },
  }),
  option: (styles, { isDisabled, isFocused, isSelected }) => {
    const hoverColor = "rgb(245,245,245)";
    const selectedColor = "rgb(220,220,220)";
    const activeColor = "rgba(37,37,37,0.08)";
    return {
      ...styles,
      backgroundColor: isDisabled
        ? undefined
        : isSelected
        ? selectedColor
        : isFocused
        ? hoverColor
        : undefined,
      color: isDisabled ? "#ccc" : "black",
      cursor: isDisabled ? "not-allowed" : "pointer",
      fontSize: "min(1rem,26px)",

      ":active": {
        ...styles[":active"],
        backgroundColor: !isDisabled
          ? isSelected
            ? undefined
            : activeColor
          : undefined,
      },
    };
  },
  menu: (provided, state) => ({
    ...provided,
    boxShadow: "-3px 3px 18px 1px rgba(0, 0, 0, 0.25)",
    borderRadius: "3px",
    marginTop: "4px",
  }),
  control: (provided, state) => {
    let obj = {
      ...provided,
      marginTop: "3px",
      marginLeft: "0px",
      marginBottom: "0.1rem",
      color: "white",
      border: state.isFocused ? "1px solid #c1c1c1" : "1px solid white",
      boxShadow: "none",
      "&:hover": {
        border: "1px solid #c1c1c1",
      },
      borderRadius: "3px",
      backgroundColor: "transparent",
      height: "1.875rem",
      maxHeight: "40px",
      padding: "0",
      minHeight: "10px",
      width: state.selectProps.formatOptionLabel ? "7rem" : "6rem",
      maxWidth: "157px",
      fontSize: "min(1rem,26px)",
      cursor: state.menuIsOpen ? "caret" : "pointer",
      caretColor: state.menuIsOpen ? "black" : "white",
    };

    if (state.menuIsOpen) {
      obj["backgroundColor"] = "white";
    }

    return obj;
  },
  input: (provided, state) => {
    let obj = {
      ...provided,
      fontSize: "min(1rem,26px)",
      height: "1.375rem",
      maxHeight: "40px",
      lineHeight: "15px",
    };

    if (state.options.length > 0) {
      obj["color"] = "black";
    } else {
      obj["display"] = "none";
    }
    return obj;
  },

  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = "opacity 300ms";
    return {
      ...provided,
      opacity,
      transition,
      color: "white",
      overflow: "unset",
    };
  },
};

const buildOptionFromVersion = (versionObject) => {
  let { name, parentVersion, id } = versionObject;
  let isDefault = versionObject.default;
  let isPinned = versionObject.pinned;
  if (name && parentVersion && id) {
    return {
      value: id,
      label: `v${parentVersion}.${name}`,
      isDefault,
      name,
      isPinned,
    };
  }

  return null;
};

const VersionSelector = ({ version, changeVersion, populateVersions, c }) => {
  const location = useLocation();
  const query = useQuery();
  const selectRef = useRef();

  const [value, setValue] = useState(null);
  const [options, setOptions] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [input, setInput] = useState("");
  const [pinSuccess, setPinSuccess] = useState(false);
  const [pinError, setPinError] = useState(false);
  const [pinMessage, setPinMessage] = useState("");

  useEffect(() => {
    for (const option of options) {
      if (option.value === version.value) {
        setValue(option);
        return;
      }
    }
  }, [version, options]);

  useEffect(() => {
    let pathname = location.pathname;
    let pageId = query.get("pageId");

    if (
      version.value === undefined &&
      options.length > 0 &&
      (pathname !== "/pages/viewpage" ||
        (pathname === "/pages/viewpage" && !pageId))
    ) {
      let optionToSet = undefined;
      for (const option of options) {
        let isDefault = option.isDefault;
        let isPinned = option.isPinned;
        if (isPinned) {
          optionToSet = option;
          break;
        }
        if (isDefault) {
          optionToSet = option;
        }
      }

      if (!optionToSet) {
        optionToSet = options[0];
      }

      changeVersion(optionToSet);
    }
  }, [changeVersion, options, version, location, query]);

  useEffect(() => {
    let populateOptions = (versions) => {
      let optionsArray = [];
      for (let i = versions.length - 1; i >= 0; i--) {
        let currentVersion = versions[i];
        optionsArray.unshift(buildOptionFromVersion(currentVersion));
      }

      if (optionsArray.length > 0) {
        setOptions(optionsArray);
      }
    };

    getAvailableVersions().then((versionsResponses) => {
      let versions = versionsResponses.data;
      populateVersions(versions);
      populateOptions(versions);
    });
  }, [populateVersions]);

  let handleChangeValue = (e) => {
    changeVersion(e);
  };

  const handleOpen = () => {
    setInput(" " + input.trim());
    setIsOpen(true);
  };

  const handleClose = () => {
    setInput("");
    setIsOpen(false);
    selectRef.current.blur();
  };

  let handleInputChange = (currentInput) => {
    if (isOpen && currentInput.length === 0) {
      setInput(" " + currentInput.trim());
    } else {
      setInput(currentInput);
    }
  };

  const showModal = () => {
    if (pinSuccess) {
      return (
        <Suspense>
          <SuccessAlert
            open={pinSuccess}
            setOpen={setPinSuccess}
            message={pinMessage}
          />
        </Suspense>
      );
    } else if (pinError) {
      return (
        <Suspense>
          <ErrorModal setterOnClose={setPinError} errorMessage={pinMessage} />
        </Suspense>
      );
    }
  };

  const handlePin = async (e, option) => {
    e.stopPropagation();
    handleClose();
    try {
      const response = await pinVersion(option.value);
      setPinMessage(`Your pinned version has been removed`);
      if (response.status === 200) {
        setOptions((oldOptions) =>
          oldOptions.map((mappedOption) => {
            mappedOption.isPinned =
              mappedOption.value === option.value &&
              mappedOption.isPinned === false;
            if (mappedOption.isPinned) {
              setPinMessage(
                `Your pinned version is now set to ${mappedOption.label}`
              );
            }
            return mappedOption;
          })
        );
        setPinSuccess(true);
      } else {
        setPinMessage(
          `There was an error pinning your version. Please try again later.`
        );
        setPinError(true);
      }
    } catch (error) {
      setPinMessage(
        `There was an error pinning your version. Please try again later.`
      );
      setPinError(true);
    }
  };

  let showComponent = () => {
    if (location.pathname === "/pages/search") {
      return (
        <div className={`version-selector-placeholder ${c ? "is-c" : ""}`} />
      );
    }

    return (
      <div id="version-selector" data-testid="versionSelector">
        {showModal()}
        <label id="version-selector-label">MX.3 Version</label>
        <Select
          ref={selectRef}
          styles={customStyles}
          value={value}
          options={options}
          menuIsOpen={isOpen}
          inputValue={input}
          onChange={handleChangeValue}
          onInputChange={(currentInput) => handleInputChange(currentInput)}
          onMenuOpen={handleOpen}
          onMenuClose={handleClose}
          components={{
            DropdownIndicator: () => (
              <ExpandMoreIcon
                className={`expand-more-icon ${isOpen ? "is-open" : ""}`}
                fontSize={"small"}
              />
            ),
          }}
          formatOptionLabel={
            c
              ? (option) => (
                  <Suspense>
                    <OptionLabel
                      option={option}
                      isOpen={isOpen}
                      handlePin={handlePin}
                    />
                  </Suspense>
                )
              : null
          }
        />
      </div>
    );
  };

  return <>{showComponent()}</>;
};

let mapStateToProps = (state) => {
  return {
    version: state.version,
    c: state.c,
  };
};

export default connect(mapStateToProps, {
  changeVersion: changeVersionAction,
  populateVersions: populateVersionsAction,
})(VersionSelector);
