// Import deps
import React from "react";
import { connect } from "react-redux";

// Import components
import TopNav from "../TopNav";
import DraggableDrawer from "../DraggableDrawer";
import GoUpButton from "../GoUpButton";
import SideButton from "../SideButton";
import "./PageLayout.css";

let PageLayout = ({
  sidebarWidth,
  config,
  navbarHeight,
  changeNavbarHeight,
  ...props
}) => {
  return (
    <React.Fragment>
      <TopNav />
      <DraggableDrawer />

      <main
        style={{
          marginLeft: `${sidebarWidth + 25}px`,
          height: `calc(100% - ${navbarHeight}px)`,
          padding: "20px 40px 0 0",
          overflowX: "clip",
        }}
        id="main-content"
        className="mb-5"
      >
        {props.children}
      </main>

      <GoUpButton />
      <SideButton
        bottom={115}
        text={config.SUPPORT_LABEL}
        href={config.SUPPORT_URL}
      />
    </React.Fragment>
  );
};

let mapStateToProps = (state) => {
  return {
    sidebarWidth: state.sidebarWidth,
    config: state.config,
    navbarHeight: state.navbarHeight,
  };
};
export default connect(mapStateToProps)(PageLayout);
