import React from "react";
import "./SideButton.css";
import {useLocation} from "react-router-dom";

const SideButton = ({ href, text, bottom }) => {

  const location = useLocation();
  const isAdmin = location.pathname.startsWith("/admin")
  const isConfidentialityManagement = location.pathname.startsWith("/confidentiality-management")

  if (!isAdmin && !isConfidentialityManagement) {
    return (

      <a
        data-testid={"sideButton-" + text}
        href={href}
        target="_blank"
        rel="noreferrer"
        className="side-button"
        style={{bottom: `${bottom}px`}}
      >
        {text}
      </a>
    );
  }
};

export default SideButton;
