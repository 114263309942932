import { addAudit } from "../api/doc/auditApi";
import reduxStore from "../helpers/reduxStore";

const Events = {
  FAVOR_DOCUMENT: "FavorDocument",
  UNFAVOR_DOCUMENT: "UnfavorDocument",
  FAVORITE_SECTION_OPEN: "FavoritesSectionOpen",
  OPEN_DOC_FROM_FAVORITE_SECTION: "OpenDocFromFavoritesSection",
  SIDE_TREE_NODE_EXPAND: "SideTreeNodeExpand",
  SIDE_TREE_NODE_COLLAPSE: "SideTreeNodeCollapse",
  SIDE_TREE_PAGE_OPEN: "SideTreePageOpen",
  BOOK_SUPPORT_CASE: "BookSupportCase",
};

export default class AuditService {
  static addFavorDocumentEvent = (pageId) => {
    AuditService.addEvent(Events.FAVOR_DOCUMENT, pageId);
  };

  static addUnFavorDocumentEvent = (pageId) => {
    AuditService.addEvent(Events.UNFAVOR_DOCUMENT, pageId);
  };

  static addFavoritesSectionOpenEvent = () => {
    AuditService.addEvent(Events.FAVORITE_SECTION_OPEN);
  };

  static addOpenDocFromFavoritesSectionEvent = (pageId) => {
    AuditService.addEvent(Events.OPEN_DOC_FROM_FAVORITE_SECTION, pageId);
  };

  static addSideTreeNodeExpandEvent = (pageId) => {
    AuditService.addEvent(Events.SIDE_TREE_NODE_EXPAND, pageId);
  };

  static addSideTreeNodeCollapseEvent = (pageId) => {
    AuditService.addEvent(Events.SIDE_TREE_NODE_COLLAPSE, pageId);
  };

  static addSideTreePageOpenEvent = (pageId) => {
    AuditService.addEvent(Events.SIDE_TREE_PAGE_OPEN, pageId);
  };

  static addBookSupportCaseEvent = (pageId) => {
    AuditService.addEvent(Events.BOOK_SUPPORT_CASE, pageId);
  };

  static addEvent = (eventName, pageId = null) => {
    let c = reduxStore.getState().c;
    if (c) {
      addAudit({
        event: eventName,
        pageId,
      }).then(() => {});
    }
  };
}
