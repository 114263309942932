import React, {useEffect, useRef, useState} from "react";
import {useNavigate} from "react-router-dom";
import {Form, FormControl} from "react-bootstrap";
import FontAwesome from "react-fontawesome";
import {useQuery} from "../utils";
import {changeSearchDataAction} from "../../actions/searchActions";
import {connect} from "react-redux";
import MindbreezeService from "../../services/MindbreezeService";

import "./SearchInput.css";
import TopNavButton from "./TopNavButton";

const SearchInput = ({defaultValue, c, changeSearchData, expandMobileSearch, setExpandMobileSearch}) => {
  const navigate = useNavigate();
  const inputRef = useRef(null);
  const query = useQuery()
  const uiStyle = c ? "client" : "mx";

  let [value, setValue] = useState(defaultValue);

  useEffect(() => {
    if (query.has("q")) {
      let currentValue = query.get("q");
      if (currentValue) {
        setValue(currentValue);
      } else {
        setValue("");
      }
    }
  }, [query]);

  let handleChangeValue = (e) => {
    setValue(e.target.value);
  };

  let onInputFocus = () => {
    // no need to trigger the user suggestion manually, we just need to init mindbreeze
    MindbreezeService.init()
      .then((result) => {
        if (inputRef && inputRef.current) {
          inputRef.current.dispatchEvent(new Event("input")); // when we click on input, show suggestion
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  let handleFormSubmit = (e) => {
    e.preventDefault();
    let value = e.target.querySelector("#query").value;
    handleRedirection(value);
  };

  let handleRedirection = (value) => {
    if (value && value.trim()) {
      let redirectedValue = encodeURIComponent(value.trim());
      changeSearchData({ query: redirectedValue });
      navigate(`/pages/search?q=${redirectedValue}`);
    }
  };

  // this method is used because, on mindbreeze load, the form won't submit on "enter" press
  let submitFormByEnterKey = (e) => {
    if ((e.which && e.which === 13) || (e.keyCode && e.keyCode === 13)) {
      e.preventDefault();
      e.target.blur();
      setValue(e.target.value);
      handleRedirection(e.target.value);
    }
  };

  let handleMobileSearchIconClick = () => {
    setExpandMobileSearch(true);
    setTimeout(() => {
      if (inputRef?.current) {
        inputRef.current.focus();
      }
    }, 10)
  }

  let renderMobileSearchIcon = () => {
    if (!expandMobileSearch) {
      return (
        <div className={`mobile-ui ${uiStyle}`}>
          <TopNavButton
            text="Search"
            onClick={handleMobileSearchIconClick}
            icon={<FontAwesome name="search"/>}
            testid="search"
          />
        </div>
      );
    }
  }

  let setDisplayClass = () => {
    if (!expandMobileSearch) {
      return `search-bar ${uiStyle}`;
    } else {
      return "";
    }
  }

  let renderSearchBar = () => {
    return (
      <Form
        id="search-form"
        className={`${setDisplayClass()}`}
        autoComplete="off"
        onSubmit={handleFormSubmit}
        data-testid="searchInput"
        data-requires-user-input="true"
          data-template="searchform"
        >
        <Form.Group className={`input-container ${uiStyle}`} controlId="query">
          <FormControl
            className="search-input"
            ref={inputRef}
            value={value}
            onChange={handleChangeValue}
            onFocus={onInputFocus}
            onBlur={() => setExpandMobileSearch(false)}
            onKeyDown={submitFormByEnterKey}
            type="text"
            autoComplete="off"
              placeholder="Search..."
              data-template="suggest"
              data-property-constraint={`ContentType:"Official Documentation"`}
              data-aria-describedby="search-description"
              data-source-id-pattern="tabs|document_property"
              data-initial-source-id-pattern="recent_query"
              data-grouped="true"
              data-placeholder="Search"
              aria-describedby="search-description"
            />
            <button id="search-submit-button">
              <FontAwesome
                className="position-absolute"
                id="search-icon"
                name="search"
              />
            </button>
          </Form.Group>
        </Form>
      );
  }

  return (
    <>
      {renderMobileSearchIcon()}
      {renderSearchBar()}
    </>
  );
};

SearchInput.defaultProps = {
  defaultValue: "",
};

let mapStateToProps = (state) => {
  return {
    searchData: state.searchData,
    c: state.c,
  };
};

export default connect(mapStateToProps, {
  changeSearchData: changeSearchDataAction,
})(SearchInput);
