import React from "react";
import {Navbar} from "react-bootstrap";
import {Link, useLocation} from "react-router-dom";
import {connect} from "react-redux";

const NavBarBrand = ({title, logo, config, c}) => {
  const location = useLocation();

  const getRedirectPath = () => {
    if (c) {
      if (config?.USER_APPLICATIONS_ACCESS?.PORTAL_ACCESS) {
        return config.CS_PORTAL_URL;
      } else {
        return "/pages/viewpage"
      }
    }

    if (location.pathname.startsWith("/admin")) {
      return "/admin";
    } else {
      return "/pages/viewpage";
    }

  }

  return (
    <Navbar.Brand
      data-testid="navBarBrand"
      as={Link}
      to={getRedirectPath()}
      className="d-flex me-auto text-decoration-none"
      id={`navbar-brand${c ? "" : "-mx"}`}
    >
      <img
        id="murex-logo"
        alt="Murex logo"
        width="144"
        src={logo}
        className="d-inline-block align-top"
      />
      <Navbar.Text id="navbar-brand-text">
        {title}
      </Navbar.Text>
    </Navbar.Brand>
  );
};

NavBarBrand.defaultProps = {
  title: "",
  logo: "",
};

let mapStateToProps = (state) => {
  return {
    config: state.config,
    c: state.c
  };
};
export default connect(mapStateToProps)(NavBarBrand);
