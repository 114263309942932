import Constants from "../actions/Constants";
import { mapChildParent, mapParentChildren } from "../utils/treeUtils";

const treeInitState = {
  version: undefined,
  data: {},
  childParentMap: {},
  parentChildrenMap: {},
};

export const treeReducer = (tree = treeInitState, action) => {
  if (action.type === Constants.UPDATE_TREE) {
    let { data, version } = action.payload;
    let childParentMap = mapChildParent(data);
    let parentChildrenMap = mapParentChildren(data);
    let source = action.payload.source;

    return { data, version, childParentMap, parentChildrenMap, source };
  }

  return tree;
};
