import reduxStore from "../helpers/reduxStore";

export default class MatomoService {
  static isInitialized = false;
  static isInitializing = false;

  static init = () => {
    return new Promise((resolve, reject) => {
      if (MatomoService.isAttachmentViewerUrl()) {
        return reject();
      }

      if (!MatomoService.isInitializing && !MatomoService.isInitialized) {
        MatomoService.isInitializing = true;

        let _paq = (window._paq = window._paq || []);
        _paq.push(["enableLinkTracking"]);
        let murexMatomoUrl = `https://${process.env.REACT_APP_MATOMO_MUREX_URL}/`;
        _paq.push(["setTrackerUrl", murexMatomoUrl + "matomo.php"]);
        _paq.push([
          "setSiteId",
          `${process.env.REACT_APP_MATOMO_MXDOC_ONLINE_SITE_ID}`,
        ]);
        _paq.push(["HeatmapSessionRecording::disable"]);
        let matomoScript = document.createElement("script"),
          s = document.getElementsByTagName("script")[0];
        matomoScript.async = true;
        matomoScript.src = `//cdn.matomo.cloud/${process.env.REACT_APP_MATOMO_MUREX_URL}/matomo.js`;

        matomoScript.onerror = () => {
          MatomoService.isInitialized = false;
          MatomoService.isInitializing = false;
          reject();
        };

        matomoScript.onload = () => {
          MatomoService.isInitialized = true;
          MatomoService.isInitializing = false;
          resolve();
        };

        s.parentNode.insertBefore(matomoScript, s);
      } else {
        reject();
      }
    });
  };

  static isAttachmentViewerUrl = () => {
    return window.location.pathname.startsWith("/download/attachment/");
  };

  static isAnonymousUser = () => {
    return (
      window.CookieScript &&
      window.CookieScript.instance.currentState().action === "reject"
    );
  };

  static pushUserInformation = () => {
    // if (MatomoService.isAnonymousUser()) {
    window._paq.push(["disableCookies"]);
    // } else {
    //   window._paq.push([
    //     "setUserId",
    //     reduxStore.getState().user.profile?.preferred_username,
    //   ]);
    // }
  };

  static pushTrackPageView = () => {
    if (window._paq) {
      let portalType = reduxStore.getState().c
        ? "MxDoc External"
        : "MxDoc Internal";

      MatomoService.pushUserInformation();

      window._paq.push(["setCustomUrl", window.location.href]);
      window._paq.push(["setDocumentTitle", document.title]);

      window._paq.push(["setCustomDimension", 1, portalType]); // PORTAL_TYPE

      window._paq.push(["trackPageView"]);
      window._paq.push(["enableLinkTracking"]);
    }
  };

  static pushTrackEvent = (
    data = { category: null, action: null, name: null, value: null }
  ) => {
    if (window._paq) {
      MatomoService.pushUserInformation();

      let { category, action, name, value } = data;
      let dataToSend = ["trackEvent", category, action];
      if (name) {
        dataToSend.push(name);
      }
      if (value !== null) {
        dataToSend.push(value);
      }

      window._paq.push(dataToSend);
    }
  };
}
