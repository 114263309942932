import instance from "../instance";
import axios from "axios";

export const getOnBehalfOfToken = (source = axios.CancelToken.source()) => {
  let cancelToken = source.token;

  return instance.get(`/v1/auth/on-behalf-of/mindbreeze`, {
    cancelToken,
  });
};
