import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import App from "./App";

// Import helpers
import reduxStore from "./helpers/reduxStore";
// Import styles
import "./assets/scss/murex.scss";
import "bootstrap/dist/js/bootstrap.min.js";
import "font-awesome/css/font-awesome.min.css";

import { initMathjax, initServiceWorker } from "./utils/init";

initServiceWorker();
initMathjax();

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <Provider store={reduxStore}>
    <App />
  </Provider>
);
