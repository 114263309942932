import axios from "axios";
import AuthService from "../services/AuthService";

axios.defaults.headers.common["Access-Control-Max-Age"] = 600;

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

instance.interceptors.request.use((config) => {
  let user = AuthService.getUserFromStorage();
  let accessToken = "";

  if (user) {
    accessToken = user.access_token;
  }

  if (!config.headers) {
    config.headers = {};
  }
  config.headers["Authorization"] = `Bearer ${accessToken}`;

  return config;
});

export default instance;
