import Constants from "./Constants";

export const changeSidebarWidthAction = (width) => {
  return {
    type: Constants.CHANGE_SIDEBAR_WIDTH,
    payload: width,
  };
};

export const openSideBarAction = () => {
  return {
    type: Constants.OPEN_SIDEBAR,
    payload: true,
  };
};

export const closeSideBarAction = () => {
  return {
    type: Constants.CLOSE_SIDEBAR,
    payload: false,
  };
};
