import React, {Suspense, useState} from "react";
import VersionSelector from "./VersionSelector";
import SearchInput from "./SearchInput";
import TopNavButton from "./TopNavButton";
import FontAwesome from "react-fontawesome";
import {useNavigate} from "react-router-dom";
import {connect} from "react-redux";

const FavoritesDialog = React.lazy(() => import("./Dialogs/Favorites/FavoritesDialog"));

const NavBarSearchContainer = ({config, version, c}) => {
  const navigate = useNavigate();
  const [expandMobileSearch, setExpandMobileSearch] = useState(false);

  let navigateToHelpUrl = () => {
    navigate(
      `/pages/findpage?docId=${config.HELP_PAGE_DOC_ID}&versionId=${version.value}`
    );
  };

  let renderFavoritesDialog = () => {
    if (c && !expandMobileSearch) {
      return (
        <Suspense>
          <FavoritesDialog version={version}/>
        </Suspense>
      );
    }
  }

  let renderVersionSelector = () => {
    if (!expandMobileSearch) {
      return (
        <VersionSelector/>
      );
    }
  }

  let renderHelpButton = () => {
    if (!expandMobileSearch) {
      return (
        <TopNavButton
          text="Help"
          onClick={navigateToHelpUrl}
          icon={<FontAwesome name="question-circle"/>}
          testid="help"
        />
      );
    }
  }

  return (
    <div
      className={`me-auto justify-content-center d-flex align-items-center ${c ? "client" : ""}`}
      id="navbar-search-container"
      data-testid="navbarSearchContainer"
    >
      <div style={{display: "contents"}}>
        {renderVersionSelector()}
      </div>
      <div id="top-buttons-container" style={{display: "contents"}}>
        <SearchInput expandMobileSearch={expandMobileSearch} setExpandMobileSearch={setExpandMobileSearch}/>
        {renderHelpButton()}
        {renderFavoritesDialog()}
      </div>
      <div style={{display: "inline-block"}}></div>
    </div>
  );
};

let mapStateToProps = (state) => {
  return {
    config: state.config,
    version: state.version,
    c: state.c,
  };
};

export default connect(mapStateToProps)(NavBarSearchContainer);
