// Import deps
import { useLocation } from "react-router-dom";
import React, { useEffect, useRef } from "react";
import { Spinner as BsSpinner } from "react-bootstrap";

export const Spinner = () => (
  <div className="d-flex justify-content-center">
    <BsSpinner
      animation="border"
      role="status"
      className="m-5"
      style={{
        width: "5rem",
        height: "5rem",
        color: "#db0a5b",
        position: "fixed",
        zIndex: "999",
      }}
    >
      <span className="visually-hidden">Loading...</span>
    </BsSpinner>
  </div>
);

export const useQuery = () => {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
};

export const usePrevious = (value) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
};

export const useHasChanged = (val) => {
  const prevVal = usePrevious(val);
  return prevVal !== val;
};
