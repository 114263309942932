import React from "react";

const MessagePage = ({ title, description, help }) => {
  let showTitle = () => {
    if (title) {
      return <h1 className="text-center text-uppercase">{title}</h1>;
    }
  };

  let showDescription = () => {
    if (description) {
      return <h3 className="text-center">{description}</h3>;
    }
  };

  let showHelp = () => {
    if (help) {
      return <p className="text-center">{help}</p>;
    }
  };

  return (
    <div
      style={{
        height: "100%",
        paddingTop: "15%",
      }}
    >
      {showTitle()}
      {showDescription()}
      {showHelp()}
    </div>
  );
};

export default MessagePage;
