import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

let ErrorPage = () => {
  const location = useLocation();
  const [message, setMessage] = useState("Error!");

  useEffect(() => {
    document.title = `Error | MX Official Documentation`;
  }, []);

  useEffect(() => {
    if (location.state) {
      setMessage(location.state.message);
    }
  }, [location]);
  return (
    <div
      style={{
        height: "100%",
        paddingTop: "15%",
      }}
    >
      <h1 className="text-center">{message}</h1>
      <h4 className="text-center"> Looks like an error has occured!</h4>
    </div>
  );
};

export default ErrorPage;
