// Import deps
import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import { AuthProvider } from "./components/AuthProvider";

// Import components
import ErrorPage from "./views/common/ErrorPage";
import LogoutPage from "./views/common/LogoutPage";
import SigninRedirectCallbackPage from "./views/common/SigninRedirectCallbackPage";
import { PrivateRoute } from "./components/Routes";
import SigninTriggerPage from "./views/common/SigninTriggerPage";
import AttachmentViewer from "./views/doc/AttachmentViewer";
import Navigate from "./components/Navigate";
import AccessDeniedPage from "./views/doc/AccessDeniedPage";
import RouteNotFoundPage from "./views/common/RouteNotFoundPage";
import PageNotFoundPage from "./views/doc/PageNotFoundPage";
import ScrollToTop from "./components/ScrollToTop";
import AdminRoutes from "./routes/AdminRoutes";
import DocRoutes from "./routes/DocRoutes";
import { connect } from "react-redux";
import {
  initAccountPopupEventListener,
  initCookieScript,
  initMatomo,
  initStopCopy,
  initStopPrinting,
  initTc,
} from "./utils/init";
import MurexMessageModal from "./components/Modals/MurexMessageModal";
import { addAuditWhiteList } from "./api/doc/auditApi";
import ConfluenceDisplayUrl from "./views/doc/ConfluenceDisplayUrl";
import ConfidentialityManagementRoutes from "./routes/ConfidentialityManagementRoutes";

const Pages = (props) => {
  return (
    <Routes>
      <Route path="/logout" element={<LogoutPage />} />

      <Route path="/signin" element={<SigninRedirectCallbackPage />} />

      <Route path="/signin-trigger" element={<SigninTriggerPage />} />

      <Route
        path="/admin/*"
        element={<PrivateRoute element={<AdminRoutes {...props} />} />}
      />

      <Route
        path="/pages/*"
        element={<PrivateRoute element={<DocRoutes {...props} />} />}
      />

      <Route
        path="/confidentiality-management/*"
        element={
          <PrivateRoute
            element={<ConfidentialityManagementRoutes {...props} />}
          />
        }
      />

      <Route
        path="/"
        element={<Navigate to="/pages/viewpage" replace={true} />}
      />

      <Route
        path="/confluence/pages/viewpage.action"
        element={
          <Navigate to="/pages/viewpage" replace={true} withParams={true} />
        }
      />

      <Route
        path="/confluence/display/:confluenceSpace/:confluenceTitle"
        element={<PrivateRoute element={<ConfluenceDisplayUrl {...props} />} />}
      />

      <Route
        path="/confluence"
        element={<Navigate to="/" replace={true} withParams={true} />}
      />

      <Route
        path="/confluence/mxdoc/findpage"
        element={
          <Navigate to="/pages/findpage" replace={true} withParams={true} />
        }
      />

      <Route
        path="/pageNotFound"
        element={<PrivateRoute element={<PageNotFoundPage />} />}
      />

      <Route
        path="/accessDenied"
        element={<PrivateRoute element={<AccessDeniedPage />} />}
      />

      <Route
        path="/routeNotFound"
        element={<PrivateRoute element={<RouteNotFoundPage />} />}
      />

      <Route path="/error" element={<PrivateRoute element={<ErrorPage />} />} />

      <Route
        path="/download/attachment/:pageId/:fileName"
        element={
          <PrivateRoute withLayout={false} element={<AttachmentViewer />} />
        }
      />

      <Route path="*" element={<RouteNotFoundPage />} />
    </Routes>
  );
};

const App = ({ c, config }) => {
  const [isShowErrorOccuredMessage, setIsShowErrorOccuredMessage] =
    useState(false);
  useEffect(() => {
    initStopCopy(c);
    initStopPrinting(c);
    initCookieScript();
    initAccountPopupEventListener(c, config?.USER_ACCOUNTS);
    initMatomo();
    initTc().catch(() => {
      setIsShowErrorOccuredMessage(true);
      addAuditWhiteList({
        event: "GATEWAY_URL_BLOCKED",
      }).then((i) => {});
    });
  }, [c, config?.USER_ACCOUNTS]);

  const showErrorOccuredMessage = () => {
    if (isShowErrorOccuredMessage) {
      return (
        <MurexMessageModal
          message={
            "An error occured while loading the application<br>Please contact your support coordinator for assistance"
          }
          display={true}
          stopScrolling={true}
          allowClose={false}
          width="600px"
        />
      );
    }
  };

  return (
    <AuthProvider>
      {showErrorOccuredMessage()}
      <Router>
        <ScrollToTop />
        <Pages />
      </Router>
    </AuthProvider>
  );
};

let mapStateToProps = (state) => {
  return {
    c: state.c,
    config: state.config,
  };
};

export default connect(mapStateToProps)(App);
