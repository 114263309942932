import Constants from "../actions/Constants";
import { updateReverseTreeByPageIdAction } from "../actions/treeActions";

const pageInitState = {
  pageId: undefined,
  version: undefined,
  source: undefined,
};

export const pageReducer = (page = pageInitState, action) => {
  if (action.type === Constants.UPDATE_PAGE) {
    action.asyncDispatch(
      updateReverseTreeByPageIdAction({
        pageId: action.payload.pageId,
        version: action.payload.version,
        source: action.payload.source,
      })
    );
    delete action.payload.source;
    return action.payload;
  }
  return page;
};
